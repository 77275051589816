<template>
<div style="display: contents;">
	<b-modal
		id="dataset-sampler-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		@ok="retrieveDatasetSample(datasetNameForSample, databaseNameForSample, samplingAmount, minMergeLength)"
		>	
		<template #modal-title>
			Dataset Sampler
		</template>
		
		<div>
			<h3>
				Sample Size
			</h3>
			<p>
				How many merge tracks would you like to retrieve?
			</p>
			<b-input type="number" v-model="samplingAmount" class="dataset-sampler-number-input"/>
		</div>

		<div>
			<h3>Length Filter (optional)</h3>
			<p>
				What is the minimum image length required for the sample? Leave blank if no filter needed
			</p>
			<b-input type="number" v-model="minMergeLength" class="dataset-sampler-number-input"/>
		</div>

		<template #modal-ok>
			Download as CSV
		</template>
	</b-modal>
	
	<b-modal
		id="qaPass-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		@ok="updateQApass(selectedDatasetUuid, selectedQApass)"
		>	
		<template #modal-title>
			QA Pass
		</template>

		<div class="my-4">
			<b-form-select v-model="selectedQApass" :options="qaOptions"/>
		</div>

		<template #modal-ok>
			Accept
		</template>
	</b-modal>
	
	<div v-if="!minimised" style="display:flex; flex-direction: row; ">
		<b-card class="card mb-0" style="background-color:transparent;">
			<div>
				<ul class="nav nav-tabs" style="width:fit-content">
					<li class="nav-item" @click="focusedTab='activeDatasets'">
						<a class="nav-link" :class="{active: focusedTab === 'activeDatasets'}">Active</a>
					</li>
					<li class="nav-item" @click="focusedTab='allDatasets'">
						<a class="nav-link" :class="{active: focusedTab === 'allDatasets'}">All Datasets</a>
					</li>
					<li v-if="qaDatasets.length" class="nav-item" @click="focusedTab='qaDatasets'">
						<a class="nav-link" :class="{active: focusedTab === 'qaDatasets'}">QA Datasets</a>
					</li>
				</ul>
				<div v-if="focusedTab === 'activeDatasets' || focusedTab === 'allDatasets'" class="dataset-contents-container">
					<div v-for="dataset in filteredDatasets" v-bind:key="dataset.uuid" class="list-group" style="width:100%">
						<div @click="selectedDataset=dataset;selectedDatasetUuid=dataset.uuid" class="list-group-item">
							{{dataset.datasetDateString}}
							<div style="float:right">
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{dataset.datasetType}}</span>
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{dataset.datasetName}}</span>
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{(dataset.vendor.split('_')[1]) ?dataset.vendor.split('_')[1]:'test_db' }}</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-power-off fa-1x" :style="dataset.inJobQueue ? 'color: #93E9BE' : 'color: white'" @click="toggleDataset(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Job queue"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-download fa-1x" @click="openDatasetSampleModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Download sample"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-check-double fa-1x" @click="updateMarkedAsFinished(dataset)"
										:style="{
											color: dataset.markedAsFinished === 1 ? '#93E9BE' : 'grey'
										}"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Mark as complete"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-eye fa-1x"
										:style="{
										color: dataset.qaPass === 1 ? '#93E9BE' : (dataset.qaPass === 0 ? 'Crimson' : 'grey')
										}"
										:disabled="!dataset.markedAsFinished"
										@click="openQApassModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="QA"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-external-link-alt"
										@click="openDatasetViewer(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="View Dataset"
									>
									</i>
								</span>
								<span class="badge badge-pill" 
									:style="{
										color: (dataset.markedAsFinished === 1 && (dataset.qaPass == 0)) ? 'rgb(212, 212, 20)' : 'grey'
									}"
									style="margin-left: 10px">
									
									<i class="fas fa-fast-backward"
										:disabled="!(dataset.markedAsFinished !== 1 || (dataset.qaPass == 1 || !dataset.qaPass))"
										@click="triggerRework(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Trigger Rework"
									>
									</i>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="focusedTab === 'qaDatasets'" class="dataset-contents-container">
					<div v-for="dataset in qaDatasets" v-bind:key="dataset.uuid" class="list-group" style="width:100%">
						<div @click="selectedDataset=dataset; selectedDatasetUuid=dataset.uuid" class="list-group-item">
							{{dataset.datasetDateString}}
							<div style="float:right">
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}" >{{dataset.datasetType}}</span>
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{dataset.datasetName}}</span>
								
								
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-download fa-1x" @click="openDatasetSampleModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Download sample"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-check-double fa-1x" @click="updateMarkedAsFinished(dataset)"
										:style="{
											color: dataset.markedAsFinished === 1 ? '#93E9BE' : 'grey'
										}"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Mark as complete"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-eye fa-1x"
										:style="{
										color: dataset.qaPass === 1 ? '#93E9BE' : (dataset.qaPass === 0 ? 'Crimson' : 'grey')
										}"
										:disabled="!dataset.markedAsFinished"
										@click="openQApassModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="QA"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-external-link-alt"
										@click="openDatasetViewer(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="View Dataset"
									>
									</i>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group" style="width:300px">
					<div v-if="(datasets.filter(r=>(r.inJobQueue===1)).length===0)  && focusedTab==='activeDatasets'" class="list-group-item list-group-item-action">
						<div style="float:right">
							No datasets in visible to labellers
						</div>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</div>
</template>

<script>
import { BButton, BCard, BFormInput as BInput, BOverlay, BTable, VBTooltip, BNav, BDropdown, BForm, BFormSelect} from 'bootstrap-vue'

import { dataAPI } from "../../http-common";
import VueContext from 'vue-context';
import DataExplorer from '@/components/dashboard/DataExplorer.vue'
import { VBModal, BModal } from 'bootstrap-vue';
import { ExportTableAsCSV } from '@/utils/files';
import Vue from 'vue';
Vue.component('b-modal', BModal);

export default {
	name: 'DatasetManager',
	props: {
	},
	components: {
		BButton,
		BCard,
		BInput,
		BOverlay,
		BTable,
		BNav,
		BDropdown,
		VueContext,
		DataExplorer,
		BForm,
		BFormSelect
	},
	directives: {
		'b-tooltip': VBTooltip,
		'b-modal': VBModal
	},
	data: () => ({
		datasets: [],
		toggleActiveDatasets: 0,
		selectedDataset: {},
		datasetStats: {},
		statsEnabled: true,
		toggleStats: 0,
		selectedDatasetUuid: '',
		minimised: false,
		focusedTab: 'activeDatasets',

		datasetNameForSample: null,
		databaseNameForSample: null,

		samplingAmount: null, 
		minMergeLength: null,
		
		//qaPass data
		selectedQApass: null,
		qaOptions: [
			{ value: null, text: 'Not assessed' },
			{ value: 1, text: 'Pass' },
			{ value: 0, text: 'Fail' },
      	],

		qaDatasets: []
	}),
	computed: {
		filteredDatasets() {
		return this.datasets.filter(dataset => {
			return (
			(Boolean(dataset.inJobQueue) === (this.focusedTab === 'activeDatasets')) ||
			this.focusedTab === 'allDatasets'
			);
		});
		},
	},
	methods: {
		async getAllDatasets(){
			this.$root.$emit('setIsLoading', true)
			this.datasets=[];
			let datasets = (await dataAPI.post('/datasets/getDatasets')).data;
			
			const { data: qaDatasets } = ( await dataAPI.get('/datasets/qaDatasets'));
			
			this.datasets = datasets;
			this.qaDatasets = qaDatasets;
			this.$root.$emit('setIsLoading', false)
		},
		async toggleDataset(dataset){
			const confirm = window.confirm(`Move ${dataset.datasetType} ${dataset.datasetDateString} ${dataset.inJobQueue ? 'out of' : 'to'} labelling queue?`);
			if(confirm){
				try{
					await dataAPI.post('datasets/updateDataset', {fieldsToUpdate: {inJobQueue: !dataset.inJobQueue}, datasetUuid: dataset.uuid});
					this.getAllDatasets();
				}
				catch(error){
					console.error(error);
					this.$noty.error('Unable to toggle dataset', {layout: 'bottomLeft'});
				}
			}
		},
		openDatasetSampleModal(dataset){
			const { dbName: databaseName, datasetName } = dataset;

			this.datasetNameForSample = datasetName;
			this.databaseNameForSample = databaseName;

			this.$root.$emit('bv::show::modal', 'dataset-sampler-modal');
		},
		
		async updateMarkedAsFinished(dataset) {
			const confirm = window.confirm(`Mark ${dataset.datasetType} ${dataset.datasetDateString} as ${!dataset.markedAsFinished ? 'complete' : 'incomplete'}?`);
			if(confirm){
				try {
					await dataAPI.post('datasets/updateDataset', {fieldsToUpdate: {markedAsFinished: !dataset.markedAsFinished}, datasetUuid: dataset.uuid});
					this.$noty.success('Completion status updated successfully', {layout: 'bottomLeft'});
					//reset QApass if set to incomplete
					if(dataset.markedAsFinished){
						await dataAPI.post('datasets/updateDataset', {fieldsToUpdate: {qaPass: null}, datasetUuid: dataset.uuid});
					}
					// refresh dataset list
					await this.getAllDatasets();
				} catch (error) {
					console.error('Error updating completion status:', error);
					this.$noty.error('Unable to update completion status', {layout: 'bottomLeft'});
				}
			}
		},
		/**
		 * @async
		 * @function
		 * @param {string} datasetUuid - pass the uuid of the dataset to update
		 * @param {object} qaPass - pass the state of the dropdown to the backend null/1/0
		 * @returns {Promise<any>} Returns a Promise that resolves with the updated datasets.
		 * @throws {Error} Throws an error if retrieval fails.
		 */
		async updateQApass(datasetUuid, qaPass) {
			try {
				await dataAPI.post('datasets/updateDataset', {fieldsToUpdate: { qaPass }, datasetUuid});
				this.$root.$emit('bv::hide::modal', 'qaPass-modal');
				this.$noty.success('QA Pass status updated successfully', {layout: 'bottomLeft'});
				// refresh dataset list
				await this.getAllDatasets();
			} catch (error) {
				console.error('Error updating QA Pass status:', error);
				this.$noty.error('Unable to update QA Pass status', {layout: 'bottomLeft'});
			}
		},
		openQApassModal(dataset) {
			if(dataset.markedAsFinished){
				this.selectedQApass = dataset.qaPass;
				this.$root.$emit('bv::show::modal', 'qaPass-modal');
			}
		},
		/**
		 * Retrieves a sample from a specified reid dataset and database.
		 *
		 * @async
		 * @function
		 * @param {string} datasetName - The name of the reid dataset to retrieve the sample from.
		 * @param {string} databaseName - The name of the database where the dataset resides.
		 * @param {number} sampleSize - The size of the sample to retrieve.
		 * @param {number} [mergeLength=undefined] - Optional. The minimum required merge track length to sample
		 * @returns {Promise<any>} Returns a Promise that resolves with the sampled data.
		 * @throws {Error} Throws an error if retrieval fails.
		 *
		 */
		async retrieveDatasetSample(datasetName, databaseName, sampleSize, mergeLength=undefined){
			try{
				const { data: sample } = await dataAPI.get(`${databaseName}/datasets/${datasetName}/sample`, { params: { sampleSize, mergeLength } });
				this.saveToCSV(sample, { datasetName, sampleSize, mergeLength });
			}
			catch(error){
				this.$noty.error("Unable to retrieve dataset sample");
				console.error(error);
			}
		},
		saveToCSV(data, details){
			const { datasetName, sampleAmount, mergeLength} = details;
			const items = data;
			const tableName =  `${datasetName}_${sampleAmount}_samples_${mergeLength}_min_length`
			let dataClone = [...items];
			ExportTableAsCSV(dataClone, tableName);
		},
		openDatasetViewer(dataset){
			this.$router.push({ name: "DatasetViewer", params: { datasetUUID: dataset.uuid}})
		},
		async triggerRework(dataset){
			if(dataset.markedAsFinished === 0 && (dataset.qaPass == 0 || !dataset.qaPass)){
				this.$noty.error("You cannot trigger a rework on an incomplete, unassessed dataset or approved dataset!", { layout: "bottomLeft" });
				return;
			}
			else{
				const prompt_ = prompt(`This is a potentially dangerous action: type in dataset name: ${dataset.datasetName}`);
				if(prompt_ !== dataset.datasetName){
					this.$noty.error("Provided dataset name does not match!", {layout: "bottomLeft"});
					return;
				}
				let confirmRework = confirm('Confirm rework?');
				if(confirmRework){
					try{
						const { data } = await dataAPI.post(`/datasets/${dataset.uuid}/rework`);
						this.$noty.success(data.status, {layout: "bottomLeft"});
					}
					catch(error){
						this.$noty.error(`Unable to trigger rework: ${error}`, {layout: "bottomLeft"});
					}
				}
			}
		}
	},
	mounted(){
		this.getAllDatasets();
	}
}
</script>

<style scoped>


	span {
		margin-left:10px
	}
	.tools .btn { margin-bottom: 10px }
	.tools .btn + .btn { margin-left: 10px }

	#explorer:hover{
		color: #007fff;
	}

	#back:hover{
		color: #007fff;
	}

	.dataset-contents-container {
		background-color: transparent;
		max-height: 600px; 
		overflow-y: auto;
	}

	.dataset-sampler-number-input {
		width: 100px;
	}

	.list-group-item:hover {
		z-index: 1;
		color: rgba(255, 255, 255, 0.75);
		text-decoration: none;
		border-color: #025274;
		transition: border-color 0.1s ease-in;
	}

	.active-dataset {
		background-color: #93E9BE; 
		color: black;
	}
	.inactive-dataset{
		background-color: gray; 
		color: white
	}
</style>