<template>
<div class="row" style="display:flex; margin-left:0.001rem; flex-direction: row; height: 100%; width:100%;background-color:transparent;">
	<div :style="`display: flex; flex-direction: row; width: ${width}; height:${height}; background-color:transparent`">
		<div class="card mb-0" :style="setCardStyle()" style="width: 100%; background-color:transparent; border: 2px solid #002A34;">
			<div v-if="showSettings" class="row" style="display:flex; gap: 32px; margin-left:0.01rem; border-bottom: 2px solid #002A34; height: 50px; padding-right: 16px; padding-left: 16px; align-items: center; ">
				<b-button size="sm" v-b-tooltip.hover="'Tile View'" variant="outline-info" style="all: unset; border: none; color: white;" @click="displayMode='tiledGallery'">
					<i class="fas fa-th"></i>
				</b-button>
				<b-button size="sm" v-b-tooltip.hover="'Gallery View'" variant="outline-info" style="all: unset; border: none; color: white;" @click="displayMode='fullframeGallery'" >
					<i class="fas fa-images"></i>
				</b-button>
			</div>
			<div v-if="displayMode === 'tiledGallery'" class="card-body" style="position: relative; width: 100%;background-color:transparent;">
				<div class="local-track-list" style=" width: fit-content; margin-left: auto;margin-right: auto;">
						<div v-for="(point,index) in pointsToDisplay" class="local-track" v-bind:key="point[pointType]">
							<div class="mask">
								<div class="card image-tile" :class="setTrackClass(index+'_pointsMutablePage', frameHoverIndex, point[pointType])" style="margin: 5px;">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										:style="imageCardSize.css"
										@mousedown="galleryElementOnClick($event, index, point)"
										@mouseover="frameHoverAction(index+'_pointsMutablePage', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null"
									>
										<div>
											
											<img
												v-if="sizeMap[detection.detectionUuid]!==undefined"
												:src="framePathsMap.get(detection.detectionUuid)"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid), sizeMap[detection.detectionUuid].width, sizeMap[detection.detectionUuid].height)"
												draggable="false"
												ondragstart="return false;"
											>
										</div>
										
									</div>
									
								</div> 
								
							</div>
							
						</div>
					</div>
					<div v-if="pointsMutable.length > pageSize" style="margin-top: 16px;">
						<b-pagination
							:per-page="pageSize"
							v-model="pageNumberRaw"
							:total-rows="pointsMutable.length"
							align="center"
							class="my-1"
						/>
				</div>
			</div>
			<div v-if="displayMode === 'fullframeGallery'" class="card-body" style="position: relative; display: flex; width: 100%;">
				<div class="local-track-list" style=" width: fit-content; margin-left: auto;margin-right: auto;">
					<!-- Gallery tiles container -->
						<div v-for="(point,index) in pointsToDisplay" 
							class="local-track" 
							v-bind:key="point[pointType]">
							<div class="mask">
								<div class="card image-tile" :class="setTrackClass(index+'_pointsMutablePage', frameHoverIndex, point[pointType])" style="margin: 5px;">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										:style="imageCardSize.css"
										@mousedown="galleryElementOnClick($event, index, point)"
										@mouseover="frameHoverAction(index+'_pointsMutablePage', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null">
										<div>
											<img
												v-if="sizeMap[detection.detectionUuid]!==undefined"
												:src="framePathsMap.get(detection.detectionUuid)"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid), sizeMap[detection.detectionUuid].width, sizeMap[detection.detectionUuid].height)"
												draggable="false"
												ondragstart="return false;">
										</div>
									</div>
								</div>
							</div>
						</div>
								
					<div v-if="pointsMutable.length > pageSize" 
						style="width: 100%; margin-top: 16px; margin-bottom: 16px; text-align: center;">
						<b-pagination
							:per-page="pageSize"
							v-model="pageNumberRaw"
							:total-rows="pointsMutable.length"
							align="center"
							class="my-1"
						/>
					</div>
					</div>
						<div style="width: 100%; margin-bottom: 16px;">
							<FullFrameDisplay 
								:key="pointsMutable[galleryIndex].detectionUuid"
								:imageScale="3"
								:size="{
									height: sizeMapFull[pointsMutable[galleryIndex].detectionUuid].height,
									width: sizeMapFull[pointsMutable[galleryIndex].detectionUuid].width,
								}"
								:subFrame="{ 
									top: pointsMutable[galleryIndex].subFrames.minimumY, 
									right: pointsMutable[galleryIndex].subFrames.maximumX, 
									bottom: pointsMutable[galleryIndex].subFrames.maximumY,
									left: pointsMutable[galleryIndex].subFrames.minimumX
								}"
								:framePath="pointsMutable[galleryIndex].framePathFull"
								:draggable="true"
							/>
						</div>
					<div>		
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import VueContext from 'vue-context';
import { dataAPI } from "../../http-common";
import {setClipCss, createImageSizeMap} from '../../utils/images.js';
import { BButton, VBTooltip, BPagination } from 'bootstrap-vue'  ;
import FullFrameDisplay from './FullFrameDisplay.vue';
import Vue from 'vue';

Vue.component('vue-context', VueContext);
Vue.component('b-button', BButton)

export default {
	name: 'DetectionGallery',
	components: {
		VueContext,
		BButton,
		FullFrameDisplay,
		VBTooltip,
		BPagination
	},
	directives: {
		'b-tooltip': VBTooltip
	},
	props: {
		points: { type: Array, default:[] },
		pointsToExclude: { type: Array, default:()=>[] },
		from: { type: String, default: '' },
		pageSize: { type: Number, default: 25 },
		to: { type: String, default: '' },
		mergeUuid: { type: String, default: '' },
		settingsEnabled: false,
		dataset: { type: String, default: '' },
		mergeable: { type: Boolean, default: false },
		sortPoints: { type: Boolean, default: true },
		pointType: { type: String, default: 'localTrackUuid' },
		width: { type: String, default: 'fit-content' },
		height: { type: String, default: 'fit-content' },
		size: { type: String, default: 'xs' },
		hasCroppedFrames: { type: Boolean, default: false },
		selectable: { type: Boolean, default: false },
		datasetUUID: { type: String, default: '' },
		multiSelect: { type: Boolean, default: true },
		showSettings: { type: Boolean, default: true },
		imagesLoaded: { type: Boolean, default: false },
		
		// Only needed when imagesLoaded is false, to support signed url retrieval
		db: { type: String, default: "" },
		portalDetails: { type: String, default: "" },
		datasetName: { type: String, default: "" },
	},
	data: function() {
		return {
			subFramesMap: new Map(),
			framePathsMap: new Map(),
			framePathsMapFull: new Map(),
			shiftKey: null,
			clickEngaged: false,
			frameHoverIndex: null,
			lastSelectedPointIndex: -1,
			selectedPointIndexes: new Set(),
			selectedCamera: 'all',
			pageNumberRaw: 1,
			settingsToggled: false,
			pipelineProgress: {'trackPoints': false, 'frames': false},
			preloadedImagesMap: new Map(),
			selectedLocalTracks: new Set(),
			toggleCameraSelect: false,
			lastVideoCreated: '',
			stagedPoints: [],
			pointsMutablePage: [],
			pointsMutable: [],
			currentDetection: undefined,
			sizeMap: {},
			sizeMapFull: {},
			displayMode: 'tiledGallery',
			galleryIndex: 0
		}
	},
	computed: {
		pageNumber(){
			return this.pageNumberRaw-1;
		},
		pointsToDisplay(){
			return this.pointsMutable.filter(r=>r.pageNumber===this.pageNumber);
		},	
		emptyPoints: function(){
			return {};
		},
		cameraSources: function(){
			const unique = (value, index, self) => {
				return self.indexOf(value) === index;
			}
			let uniqueCameras = this.points.map(r=>r.sourceNodeUuid).filter(unique).sort((a, b) => {
				return parseInt(a.split('-')[1]) - parseInt(b.split('-')[1]);
			});

			uniqueCameras.push('all');

			return uniqueCameras;
		},
		totalPages: function(){
			return Math.ceil(this.points.length/this.pageSize)
		},
		selectedLocalTracksArray(){
			return Array.from(this.selectedLocalTracks);
		},
		imageCardSize(){
			if(this.size === 'xl'){
				const width = 120;
				return {
					fixedCropWidth: width,
					css: `width: ${width}px; height: ${width*1.7}px;`
				};
			}
			if(this.size === 'xs'){
				const width = 60;
				return {
					fixedCropWidth: width,
					css: `width: ${width}px; height: ${width*1.7}px;`
				};
			}
			else{
				const width = 75;
				return {
					fixedCropWidth: 75,
					css: `width: ${width}px;`
				};
			}
		},
	},
	watch: {
		pageNumber: async function(newval){
			this.updatePipeline(this.pointsMutable.filter(r=>r.pageNumber  === newval));
		},
		points: function(newVal){
			this.pointsMutable = newVal;
			let page = 0;
			this.pointsMutable.forEach((point, index) => {
				if (((index+1) % this.pageSize) === 0){
					point['pageNumber'] = page++;
				}
				else{
					point['pageNumber'] = page;
				}
			})
			this.pointsMutablePage = this.pointsMutable.slice((this.pageNumber*this.pageSize),(this.pageNumber*this.pageSize)+this.pageSize);
			this.updatePipeline(this.pointsMutable.filter(r=>r.pageNumber  === this.pageNumber));
		},
		currentDetection: function(newVal){
			this.$emit('setHighLightedDetection', newVal);
		},
		selectedLocalTracksArray: function(newVal){
			console.log('watcher is working')
			this.$emit('updateSelectedLocalTracks', newVal);
		}
	},
	mounted: function() {
		this.pointsMutable = (this.sortPoints) ? this.points.sort((a, b) => a['timestamp'] - b['timestamp']) : this.points;
		let page = 0;
		this.pointsMutable.forEach((point, index) => {
			if (((index+1) % this.pageSize) === 0){
				point['pageNumber'] = page++;
			}
			else{
				point['pageNumber'] = page;
			}
		})
		this.updatePipeline(this.pointsMutable.filter(r=>r.pageNumber === this.pageNumber));
	},
	methods: {
		async updatePipeline(points){
			this.$root.$emit('setIsLoading', true);

			if(this.imagesLoaded){
				
				
				const constructFrameMap = (points, key) => {
					return points.reduce((frameMap, r) => {
						frameMap.set(r.detectionUuid, r[key]);
						return frameMap;
					}, new Map());
				}

				this.framePathsMap = constructFrameMap(points, 'framePath');
				this.framePathsMapFull = constructFrameMap(points, 'framePathFull');


				this.subFramesMap = points.reduce((subFrameMap, r)=>{
					subFrameMap.set(r.detectionUuid, {
						top: r.minimumY,
						right: r.maximumX,
						bottom: r.maximumY,
						left: r.minimumX
					})

					return subFrameMap;
				
				},new Map())

				points.forEach(async point => {
					let image = new Image();
					let path = this.framePathsMap.get(point.detectionUuid);
					image.src = path;
					this.preloadedImagesMap.set(point.detectionUuid, image);
				})
			}
			else{
				// If no frame paths are supplied by parent component, retrieve them here as and when needed
				let pointsToRequest=[];
				const { datasetName, portalDetails, db } = this;

				pointsToRequest=points.filter(r=>!this.framePathsMap.get(r.detectionUuid));
				
				// only request point not in cache:
				if(pointsToRequest.length){
					const supportingData = await this.retrieveFramePaths(pointsToRequest, true, datasetName, portalDetails, db)

					this.subFramesMap = points.reduce((subFrameMap, r)=>{
						subFrameMap.set(r.detectionUuid, {
							top: r.minimumY,
							right: r.maximumX,
							bottom: r.maximumY,
							left: r.minimumX
						})
						return subFrameMap;
					}, new Map());

					this.framePathsMap = this.createFramePathMap(supportingData);

					supportingData.forEach(async point => {
						let image = new Image();
						let path = this.framePathsMap.get(point.detectionUuid);
						image.src = path;
						this.preloadedImagesMap.set(point.detectionUuid, image);
					})
				}	
			}

			this.sizeMap = {...this.sizeMap, ...(await createImageSizeMap(this.framePathsMap))};	

			if(Object.keys(points[0]).includes('framePathFull')){
				this.sizeMapFull = {...this.sizeMapFull, ...(await createImageSizeMap(this.framePathsMapFull))};
			}
			
			this.$root.$emit('setIsLoading', false);
		},
		stagedPointsSorted: function(){
			return this.stagedPoints;
		},
		retrieveSubFrames(points){
			if(points.length > 0){
				const detections = points.map(r=>r.detectionUuid).map(uuid => `'${uuid}'`).toString();
				return dataAPI
					.post(`/detections/subFrames`, { detections: detections, from: this.from, to: this.to, dataset: (this.dataset==='') ? '' : this.dataset})
					.catch(e => {
						console.log(e);
					})
			}
			else {
				return { data: [] }
			}
		},
		retrieveFrames(points){
			let url = (this.dataset === '') ? `frame/get/track_points/rgb/jpg/hfm1` : `frame/get/track_points/rgb/jpg/${this.dataset}`;
			if(points.length>0){
				return dataAPI.post(url, {
					from: this.from,
					to: this.to,
					track_points: points.map(r=>r.detectionUuid),
					isBenchMark: (this.dataset === '') ? false : true
				});
			}
			else{
				return {data:[]}
			}
		},
		async retrieveFramePaths(detections, cropped, datasetName, portalDetails, db){
			try{
				const { data } = await dataAPI.post(`${db}/frames/frameDetails`, { detections, cropped: true, datasetName, portalDetails, comingFrom: "detectionGallery.vue" });
				return data;
			}
			catch(error){
				console.error(error);
				this.$noty.error("Failed to retrieve image urls");
			}
		},
		createFramePathMap: function(rawData) {
			let _framePathsMap = new Map();
			rawData.forEach(path => _framePathsMap.set(path.detectionUuid, path.frame));

			return _framePathsMap;
		},
		createSubFramesMap: function(rawData) {
			let _subFramesMap = new Map();
			rawData
				.filter(subFrame => subFrame.label === 'person' && subFrame.textureLabel === 'rgb')
				.forEach(subFrame => _subFramesMap.set(subFrame.detectionUuid, {
					top: JSON.parse(subFrame.subFrames)[0].min_y,
					right: JSON.parse(subFrame.subFrames)[0].max_x,
					bottom: JSON.parse(subFrame.subFrames)[0].max_y,
					left: JSON.parse(subFrame.subFrames)[0].min_x
				}));
			return _subFramesMap;
		},

		localSetClipCss: function(coords, width, height) {
			return setClipCss(coords, width, height, this.imageCardSize.fixedCropWidth, this.hasCroppedFrames);
		},
		frameHoverAction: function(index, detectionUuid){

			//updates frame index to display on track map
			this.frameHoverIndex = index;

		},
		frameClicked: function(frameIndex, event) {

			let frameIndexValue=parseInt(frameIndex.split('_')[0])
			
			if (this.multiSelect === false) {
				// Clear previous selections if multiSelect is false
				this.selectedPointIndexes.clear();
				this.selectedLocalTracks.clear();
			}
			if (this.selectedPointIndexes.has(frameIndex)) {
				console.log(`... remove ${frameIndexValue}`);
				this.lastSelectedPointIndex = -1;
				this.selectedPointIndexes.delete(frameIndex)
				let galleryToChange = (frameIndex.split('_')[1] === 'stagedPoints') ? this.stagedPoints: this.pointsMutable;

				this.selectedLocalTracks.delete(galleryToChange.filter(r=>r.pageNumber === this.pageNumber)[frameIndexValue][this.pointType])
				this.$emit('updateSelectedLocalTracks', this.selectedLocalTracks);

			} else {
				this.selectedPointIndexes.add(frameIndex);
				let galleryToChange = (frameIndex.split('_')[1] === 'stagedPoints') ? this.stagedPoints: this.pointsMutable.filter(r=>r.pageNumber === this.pageNumber);
				this.selectedLocalTracks.add(galleryToChange[frameIndexValue][this.pointType])
				this.$emit('updateSelectedLocalTracks', this.selectedLocalTracks);
				this.lastSelectedPointIndex = -1;
			}
		},
		selectedByDrag(frameIndex, event){
			if (event.which === 1 && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex < frameIndex) {
				for (let i = this.lastSelectedPointIndex + 1; i <= frameIndex; i++) {
					this.selectedPointIndexes.add(i);
				}
				this.lastSelectedPointIndex = frameIndex;
			}
			else if (event.which === 1 && this.lastSelectedPointIndex !== -1 && this.lastSelectedPointIndex > frameIndex) {
				for (let i = frameIndex; i <= this.lastSelectedPointIndex; i++) {
					this.selectedPointIndexes.add(i);
				}
				this.lastSelectedPointIndex = frameIndex;
			}
		},
		setTrackClass(index, frameHoverIndex, localTrackUuid){
			let _this = this;
			return {active: frameHoverIndex === index, selected: _this.selectedLocalTracks.has(localTrackUuid)}
		},
		setCardStyle(){
			if(!this.settingsToggled){
				return 'width: 100%;'
			}
			else{
				return 'width:848px;'
			}
		},
		merge(){
			let filteredPoints = this.pointsMutable.filter(r => this.selectedLocalTracks.has(r.localTrackUuid));

			filteredPoints.forEach(r=>{
				this.stagedPoints.push(r);
			});

			this.pointsMutable = this.pointsMutable.filter(r => !this.selectedLocalTracks.has(r.localTrackUuid));
			this.lastSelectedPointIndex = -1;
			this.selectedPointIndexes = new Set();
			this.selectedLocalTracks = new Set();
		},
		removeSelected(){
			let pointsToRemove = this.stagedPoints.filter(r => this.selectedLocalTracks.has(r.localTrackUuid));
			this.stagedPoints = this.stagedPoints.filter(r => !this.selectedLocalTracks.has(r.localTrackUuid))
			this.pointsMutable = [...this.pointsMutable , ...pointsToRemove]
			this.lastSelectedPointIndex = -1;
			this.selectedPointIndexes = new Set();
			this.selectedLocalTracks = new Set();
		},

		imageSelected(point){
			this.$emit('imageSelected', point);
		},
		reset(){
			this.selectedPointIndexes = new Set();
			this.selectedLocalTracks = new Set();
		},
		galleryElementOnClick($event, index, point){
			if(this.selectable){
				this.galleryIndex = index;
				this.imageSelected(point);
				this.frameClicked(index+'_pointsMutablePage', $event); 
				(this.frameHoverIndex === index+'_pointsMutablePage') ? this.frameHoverIndex = -1 : null; this.lastSelectedPointIndex=index+'_pointsMutablePage';
			}
		}
	}
}

</script>

<style scoped>
.local-track-list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding-right:0px;
	flex-direction: row;
}

.local-track-list .local-track {
	box-sizing: border-box;
}
.track-highlight {
	display: inline-block;
	border-style: solid;
	border-top-width: 3px;
	border-bottom-width: 3px;
	margin-top: 0px;
	margin-bottom: 2px;
	background-color: lightgrey;
	border-color: gray;
}

.image-tile{
	border: 2px solid transparent;
}
.image-tile.active{
	border: 2px solid lightgreen;
}

.image-tile.selected{
	border: 2px solid red;
}

.mask.selected {
	border: 2px solid red;
}

.local-track-list .local-track.col2 {
	flex: 1 0 50%;
}
.local-track-list .local-track.col3 {
	flex: 1 0 33%;
}
.local-track-list .local-track.col4 {
	flex: 1 0 25%;
}
.local-track-list .local-track.col5 {
	flex: 1 0 0%;
}
.local-track-list .local-track.col10 {
	flex: 1 0 1%;
}

.local-track .frame-item {
	float: left;
}

.local-track-list pre {
	float: left;
	font-size: 12px;
}

.full-frame-container{
	position: fixed;
	top: 8px;
	right: 16px;
	font-size: 18px;
	z-index: 10;
}

.mergeUuidLinking {
	position: relative;
	margin: 0px;
	width: 660px;
	height: 120px;
	overflow:hidden;
}

i:hover {
	color: #007fff;
}
</style>
