<template>
<div class="fill">
	<div class="fill">
		<div class="card-header" style="align-items:center;width: 100%; display: flex; flex-direction: row;margin-bottom:10px;">
			<div style="align-items: center;">
				Task ID: {{taskInfo.taskUuid}}
				
			</div>
			
			<div style="margin-left: auto;">
				<button class="btn btn-info" @click="toggleImageCardSize">
					<i :class="`fas fa-search-${size === 'xl' ? 'minus' : 'plus'}`"/>
				</button>
				Merges remaining: {{ mergesRemaining }} 
				
				<button
					type="button"
					class="btn btn-outline-info"
					style="border: 2px solid #007fff; height: 30px; width:40px; margin-left:10px;"
					@click="finishTask()"
				>
					<i class="fas fa-check" style="position: relative;left:-1px; top:-5px;"></i>
				</button>
			</div>
		</div>
	
		<div style="display:flex;flex-direction: row; width:100%; height:70vh;">
			<div class="card mb-3" style="margin-left:0px; margin-right:10px;background-color: transparent;height:100%;">
				<p class="card-header">Merge List</p>
				<div style="height: 100%; overflow-y: auto; border: 1px solid #002834; ">
					<ul v-for="(mergeTrack) in mergesToDisplay" class="list-group" id="mergeList" :key="mergeTrack">
						<li
							v-if="mergeListStatus[mergeTrack] === 'unlabelled'"
							class="list-group-item d-flex justify-content-between align-items-center" :id="`${(currentMergeTrack===mergeTrack)?'active':'inactive'}`"
							:style="`border: 1px solid ${(mergeListStatus[mergeTrack]!=='unlabelled') ? `${(mergeListStatus[mergeTrack]==='matchesFound') ? 'green': 'red'}` : 'transparent'}`"
							style="padding:8px;color: white;font-size:0.7em;"
							@click="currentMergeTrack=mergeTrack;">
							{{mergeTrack}}
						</li>
					</ul>
					<li
						v-if="mergesRemaining === 0"
						class="list-group-item d-flex justify-content-between align-items-center"
						style="border: 1px solid transparent;padding:8px;color: white;font-size:0.7em;"
					>
						No Merge Tracks Available
					</li>
				</div>
			</div>

			<!-- TARGET -->
			<div v-if="mergesRemaining" class="card mb-3" style="display: flex; flex-direction: column; width: 30%; background-color: transparent; margin-right:10px;height:100%">
				<p class="card-header">Target</p>
				<MergeTrackEditor
					v-if="currentMergeTrack"
					:db="db"
					@imageHover="setSelectedTargetHover"
					:inputTable="taskInfo.tableName"
					:mergeUuid="currentMergeTrack"
					:key="currentMergeTrack"
					:resolution="1920"
					:minimal="true"
					ref="mergeEditor"
					:size="size"
					style="border:1px solid transparent;"
					:mode="(consolidateTaskUuid !== '') ? 'read' : 'read/write'"
					:portalDetails="portalDetails"
				>
				</MergeTrackEditor>

			</div>

			<!-- GALLERY -->
			<div v-if="mergesRemaining" class="card mb-3" style="background-color: transparent;width: 30%;margin-right:10px;height:100%">
				<p class="card-header">Gallery</p>

				<div v-if="galleryStatus[currentMergeTrack]==='loaded'" style="overflow-y: auto; height:100%">
					<DetectionGallery
						v-if="galleryMerges[currentMergeTrack] && galleryMerges[currentMergeTrack].length!==0"
						style="border: 0px solid transparent"
						:imagesLoaded="false"
						:points="galleryMerges[currentMergeTrack]"
						:hasCroppedFrames="hasCroppedFrames"
						pointType="mergeUuid"
						:sortPoints="false"
						:pageSize="50"
						:showSettings="false"
						:multiSelect="true"
						:mergeable="false"
						:size="size"
						:selectable="true"
						ref="gallery"


						:db="db"
						:portalDetails="portalDetails"
						:datasetName="datasetName"
						
						
						:key="currentMergeTrack"
						width="100%"
						@updateSelectedLocalTracks="updateSelectedGalleryPoints"
						@imageSelected="galleryClickAction"
					/>
					
					<div v-if="galleryMerges[currentMergeTrack].length===0">
						<p class="card-header" style="align-self:center">No merges in gallery</p>
					</div>
				</div>

				<div v-else>
					<b-overlay style="margin-top: 50%" :show="true" variant="transparent" blur= "5px" rounded="sm" >

					</b-overlay>
				</div>

			</div>

			<!-- GALLERY SELECTION -->
			<div v-if="mergesRemaining" class="card mb-3" style="display:flex; flex-direction: column; width: 30%; background-color: transparent;margin-right:0px;overflow-x: hidden; overflow-y: auto; max-height:80vh;height:100%" >
				<p v-if="currentGalleryMergeSelected!==''" class="card-header">Merge Viewer: {{currentGalleryMergeSelected.mergeUuid}}</p>
				<p v-else class="card-header">Select a gallery merge to view below...</p>
				

				<!-- Merge editor to expand merges in gallery -->

				<MergeTrackEditor
					v-if="currentGalleryMergeSelected!==''"
					:db="db"
					:inputTable="taskInfo.tableName"
					:mergeUuid="currentGalleryMergeSelected.mergeUuid"
					:key="currentGalleryMergeSelected.mergeUuid"
					:resolution="1920"
					:minimal="true"
					:size="size"
					@imageHover="setSelectedGalleryHover"
					ref="mergeEditor"
					style="width: 100%;border:1px solid transparent;"
					:mode="(consolidateTaskUuid !== '') ? 'read' : 'read/write'"
					:portalDetails="portalDetails"
				>
				</MergeTrackEditor>

			</div>
		</div>
	</div>

	<div>
		<button
			v-if="selectedGalleryMerges.length!==0"
			type="button"
			class="btn btn-outline-info"
			style="border: 2px solid #007fff;margin-top:10px;"
			@click="consolidateMergeTracks()"
		>
			Consolidate {{selectedGalleryMerges.length}} with target
		</button>

		<button
			v-if="selectedGalleryMerges.length===0 && mergesRemaining"
			type="button"
			class="btn btn-outline-warning"
			style="border: 2px solid #C44315;margin-top:10px;"
			@click="noMatch()"
		>
			Unable to find a match
		</button>
		{{user}}
		<button
			v-if="selectedGalleryMerges.length>0"
			type="button"
			class="btn btn-outline-warning"
			style="border: 2px solid #C44315;margin-top:10px;margin-left:10px"
			@click="clearGallerySelection()"
		>
			Clear selection
		</button>
	</div>
</div>

</template>
<script>

import { dataAPI } from "../../http-common.js";
import MergeTrackEditor from '../MergeTrackEditor.vue';
import DetectionGallery from '@/components/tools/DetectionGallery.vue';
import { PickProperties } from '@/utils/objects';


export default {
	name: 'consolidation-workflow',
	components: {
		MergeTrackEditor,
		DetectionGallery
	},
	data: () => ({
		targetMerges: [],
		galleryMerges: {},
		galleryStatus: {},
		taskInfo: {},
		framePathsMap: null,
		subframesMap: null,
		currentGalleryMergeSelected: '',
		selectedGalleryMerges: [],
		currentMergeTrack: '',
		mergeListStatus: {},
		currentTargetHover: '',
		currentGalleryMergeSelectedHover: '',
		db: "",
		size: 'xs',
		hasCroppedFrames: false,
		portalDetails: null,
		datasetName: "",
	}),
	props: {

		consolidateTaskUuid: {type: String, default: ''},
	},
	mounted: async function() {

		const { data } = (await dataAPI.post(`/getConsolidateTaskInfo`, {taskUuid: this.consolidateTaskUuid}));
		const datasetName = data.taskInfo.tableName;
		this.datasetName = datasetName;
		
		const dbName = data.taskInfo.databaseName.split("_")[0];
		
		const { data: datasetInfo } = await dataAPI.post(`${dbName}/datasets/getDataset`, {localTrackList: datasetName});
		
		this.hasCroppedFrames = datasetInfo.hasCroppedFrames && true;

		const { datasetConfig } = datasetInfo;
		
		if(datasetConfig?.portal_ks_config){
			const {deployment_code: deploymentCode, dataset_name: portalDatasetName} = datasetInfo.datasetConfig.portal_ks_config;
			this.portalDetails = {deploymentCode, datasetName: portalDatasetName}; 
		}
		
		this.db = dbName;
		this.taskInfo = data.taskInfo;

		const consolidationTaskState = this.retrieveLocalConsolidationTaskState(this.consolidateTaskUuid);

		if(!consolidationTaskState){
			// fresh task load, load from db
			this.targetMerges = Array.from(new Set(data.mergesRequiringConsolidation));
			this.targetMerges.forEach(merge => {
				this.$set(this.galleryStatus, merge, 'not loaded');
				this.$set(this.mergeListStatus, merge, 'unlabelled');
			});
			this.setLocalConsolidationTaskState(this.consolidateTaskUuid, this.mergeListStatus);
		}
		else {
			// load from cache
			const consolidationTaskState = this.retrieveLocalConsolidationTaskState(this.consolidateTaskUuid);
			this.targetMerges = Object.keys(consolidationTaskState).filter(key => consolidationTaskState[key] === 'unlabelled');
			this.mergeListStatus = consolidationTaskState;
			this.targetMerges.forEach(merge => {
				this.$set(this.galleryStatus, merge, 'not loaded');
			});
		}

		this.currentMergeTrack = this.targetMerges[0];
		const gallery = (await dataAPI.post(`${this.db}/feature/${this.taskInfo.tableName}/getSimilarMergeTracks`, {mergeUuid: this.targetMerges[0], hasPreCroppedFrames: true})).data;
		this.$set(this.galleryMerges, this.targetMerges[0], gallery.filter(r=>r.mergeUuid !== this.targetMerges[0]));
	},
	computed: {
		mergesRemaining(){
			return Object.keys(this.mergeListStatus).filter(key => this.mergeListStatus[key] === 'unlabelled').length;
		},
		showGallery(){
			{return this.framePathsMap && this.subframesMap && this.targetMerges.length }
		},
		mergesToDisplay(){
			// retrieve only keys that have value 'unlabelled'
			return Object.entries(this.mergeListStatus).flatMap(([key, value]) => value === 'unlabelled' ? [key] : []);
		}
	},
	watch: {
		targetMerges(newVal){
			this.retrieveFramesAndSubframes(newVal);
		},

		async currentMergeTrack(newVal){
			const validMergeTrack = await this.checkMergeTrack(newVal);
			
			if(!validMergeTrack){
				this.$noty.info("Removing empty merge track", {layout: "bottomLeft"});
				this.handleInvalidMergeTrack(newVal);
			}

			this.selectedGalleryMerges = [];
			this.currentGalleryMergeSelected = '';
			this.currentGalleryMergeSelectedHover = '';

			let gallery = (await dataAPI.post(`${this.db}/feature/${this.taskInfo.tableName}/getSimilarMergeTracks`, {mergeUuid: newVal, hasPreCroppedFrames: true})).data;
			
			gallery = gallery.map(({detectionUuid, minimumX, minimumY, maximumX, maximumY, mergeUuid, distance}) => {
				return {
					detectionUuid,
					minimumX, 
					minimumY, 
					maximumX, 
					maximumY, 
					mergeUuid, 
					distance,
				}
			});

			this.$set(this.galleryMerges, newVal, gallery.filter(r=>r.mergeUuid !== newVal));
			this.$set(this.galleryStatus, newVal, 'loaded');
		}
	},
	methods: {
		retrieveLocalConsolidationTaskState(taskUUID){
			try{
				const consolidationTaskLocalState = JSON.parse(localStorage.getItem('consolidationTaskState'));
				if(consolidationTaskLocalState == null){
					return undefined;
				}
				else{
					return consolidationTaskLocalState[taskUUID]??undefined;
				}
			}
			catch(error){
				// corrupted cache or invalid json, whatever the case, load stale state from db, better than having page crash
				return undefined
			}
		},
		setLocalConsolidationTaskState(taskUUID, taskState){
			try{
				const consolidationTaskLocalState = JSON.parse(localStorage.getItem('consolidationTaskState'));
				if(consolidationTaskLocalState == null){
					const stateObject = {}
					stateObject[taskUUID] = taskState;
					localStorage.setItem('consolidationTaskState', JSON.stringify(stateObject));
				}
				else{
					consolidationTaskLocalState[taskUUID] = taskState;
					localStorage.setItem('consolidationTaskState', JSON.stringify(consolidationTaskLocalState));
				}
			}
			catch(error){
				this.$noty.error(`Could not save current task state: ${error}`, { layout: "bottomLeft" });
			}
		},
		async checkMergeTrack(mergeUuid){
			const { data } = await dataAPI.post(`/${this.db}/getMergeTrackInfo`, { mergeUuid });
			const mergeTrackContents = data.mergeInfo;
			return (mergeTrackContents.length > 0);
		},
		handleInvalidMergeTrack(invalidMergeUuid){
			// prevent blocking annotator from progressing through task
			this.targetMerges = this.targetMerges.filter(mergeUuid => mergeUuid !== invalidMergeUuid);
			// reset to first merge track in list
			this.currentMergeTrack = this.targetMerges[0];
			
			// delete from mergelist status
			const filteredMergeListStatus = { ...this.mergeListStatus };
			delete filteredMergeListStatus[invalidMergeUuid];
			this.$set(this, 'mergeListStatus', filteredMergeListStatus);
			this.setLocalConsolidationTaskState(this.consolidateTaskUuid, this.mergeListStatus);
		},
		async retrieveFramesAndSubframes(merges){
			const url = `${this.db}/frame/get/getFramesAndSubFramesForMerges`;
			const {data} = (await dataAPI.post(url, {mergeUuids: merges, dataset: this.taskInfo.tableName}))
			this.framePathsMap = this.createFramePathMap(data);
			this.subframesMap = this.createSubFramesMap(data);
		},
		async retrieveFramePaths(detections, cropped, datasetName){
			try{
				const portalDetails = this.portalDetails??{};
				const { data } = await dataAPI.post(`${this.db}/frames/frameDetails`, { detections, cropped, datasetName, portalDetails });
				return data;
			}
			catch(error){
				console.error(error);
				this.$noty.error("Failed to retrieve image urls");
			}
		},
		createFramePathMap: function(rawData) {
			let _framePathsMap = new Map();
			rawData.forEach(path => _framePathsMap.set(path.detectionUuid, path.frame));
			return _framePathsMap;
		},
		createSubFramesMap: function(rawData) {
			let _subFramesMap = new Map();
			rawData
				.forEach(subFrame => _subFramesMap.set(subFrame.mergeUuid, {
					top: subFrame.minimumY,
					right: subFrame.maximumX,
					bottom: subFrame.maximumY,
					left: subFrame.minimumX
				}));
			return _subFramesMap;
		},
		
		galleryClickAction: function(point){

			this.currentGalleryMergeSelected=point;
			this.subframesMap.set(point.mergeUuid, {
				top: point.minimumY,
				right: point.maximumX,
				bottom: point.maximumY,
				left: point.minimumX
			});
		},
		updateSelectedGalleryPoints(points){
			this.selectedGalleryMerges=Array.from(points);
		},
		consolidateMergeTracks: function(){
			const confirm = window.confirm('Confirm you have reviewed the merge track editor for each merge you are consolidating. Press esc key to cancel, enter key to proceed.');
			if(confirm){
				const {currentMergeTrack, selectedGalleryMerges, taskInfo, mergeListStatus, targetMerges} = this;
				const url = `/${this.db}/consolidate`;
				const body = {consolidateEntries: [currentMergeTrack, ...selectedGalleryMerges], dataset: taskInfo.tableName};

				dataAPI.post(url, body)
					.then(response => {
						// If error detected in consolidation, tells user to try again
						if(response.data.state === 'rejected'){
							this.$noty.error('A reviewer has previously detected errors in your consolidation, check the merge track editor for errors and try a different selection of merges to consolidate',{layout: 'bottomLeft'})
						}
						else{
							this.$noty.success('Consolidation accepted!',{layout: 'bottomLeft'});
							this.$set(mergeListStatus, currentMergeTrack, 'matchesFound');
							this.setLocalConsolidationTaskState(this.consolidateTaskUuid, this.mergeListStatus);
							const info = {
								status: "match found",
								mergeUuid: currentMergeTrack,
								consolidatedMerges: selectedGalleryMerges,
								taskUuid: taskInfo.taskUuid
							}

							this.addLog('consolidation', info);

							

							// moves to next merge in list
							this.currentMergeTrack = targetMerges[Math.min(targetMerges.indexOf(currentMergeTrack)+1, targetMerges.length-1)];

							// remove consolidated merges from current list if applicable and set them as 'matches found'
							this.targetMerges = this.targetMerges.filter(r=>!selectedGalleryMerges.includes(r));
							selectedGalleryMerges.forEach(mergeUuid => {
								this.$set(mergeListStatus, mergeUuid, 'matchesFound');
							});
							this.setLocalConsolidationTaskState(this.consolidateTaskUuid, this.mergeListStatus);
							// resets selection state
							this.selectedGalleryMerges = [];
						}
					})
					.catch(e => {
						console.log(e);
					});
			}
		},
		noMatch(){
			const confirm = window.confirm('Confirm you have not found a match in all pages of the gallery, Press esc key to cancel, enter key to proceed.');
			if(confirm){
				const {targetMerges,currentMergeTrack, mergeListStatus} = this;
				this.$set(mergeListStatus, currentMergeTrack, 'noMatchesFound');
				this.setLocalConsolidationTaskState(this.consolidateTaskUuid, this.mergeListStatus);
				this.currentMergeTrack = targetMerges[Math.min(targetMerges.indexOf(currentMergeTrack)+1, targetMerges.length-1)];
				const info = {
					status: "no match found",
					mergeUuid: currentMergeTrack,
					consolidatedMerges: [],
					taskUuid: this.taskInfo.taskUuid
				}

				this.addLog('consolidation', info);
			}
		},
		async addLog(action, info){
			const user = localStorage.getItem('username');
			try{
				console.log(`/${this.db}/addLog`, {user, action, info})
				const result = await dataAPI.post(`/${this.db}/addLog`, {user, action, info});
				return result;
			}
			catch(error){
				console.log(error)
				return error;
			}
		},
		finishTask(){
			const { mergesRemaining, taskInfo }=this;
			if(mergesRemaining>0){
				this.$noty.error(`Unable to finish task: task contains pending merges`, {layout: 'bottomLeft'});
			}
			else{
				let confirm  = window.confirm('Submit Task as finished?');
				if (confirm){
					dataAPI.post(`/updateTask`,{status: "pendingApproval", taskUuid: taskInfo.taskUuid, taskType: 'consolidation'}).then(response=>{
						const nextUrl = window.location.href.split('/')[0]+"/"+window.location.href.split('/')[1];
						window.location.replace(`${nextUrl}`+'dashboard');
					}).catch(error => {
						this.$noty.error(`Unable to submit task as finished: ${error}`, {layout:"bottomLeft"});
					})
				}
			}
		},
		setSelectedGalleryHover(point){
			this.currentGalleryMergeSelectedHover = point;
		},
		setSelectedTargetHover(point){
			this.currentTargetHover = point;
		},
		clearGallerySelection(){
			this.selectedGalleryMerges = [];
			this.currentGalleryMergeSelected='';
			this.$refs.gallery.reset();
		},
		toggleImageCardSize: function() {
			this.size = this.size === 'xs' ? 'xl' : 'xs';
		}
	},

}
</script>

<style scoped>

.fill{
	height: 100%;
	width:100%;
}

.track-highlight.new-merge {
	background-color: rgba(127,127,127,0.5);
	border: 2px dashed #fff3;
}

.ff-canvas_fullframe_preview{
	/* width: 10%; */
	/* height: 10%; */
	/* position: fixed;
	top: 8px;
	
	right: 8px;
	font-size: 18px; */
	/* z-index: 10; */
	position: relative;
	/* border: 1px solid red; */
}


#mergeList:hover {
	background-color: #002B36;
	opacity: 0.5;
}
#mergeList #active {
	background-color: #268BD2;
	opacity: 1;
}


</style>