<template>
<div style="display:flex; flex-direction: column;height:100%">
	<b-modal
		id="fullframe-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		hide-footer
	>
		<FullFrameDisplay 
			style="height: 1000px; width: 500px;"
			:imageScale="2.7"	
			:framePath="currentFullFrameImage"
			:size="fullFramePathSizeMap[currentDetectionUuid]"
			:subFrame="currentFullFrameSubFrame"
		/>

	</b-modal>
	<div class="sidebar">
		<div class="sidebar-scroll-wrapper">
			<div v-if="globalMergedTracks" style="width:100%">
				<h1 v-if="globalMergedTracks.length">
					<code>Merge  {{currentMergeTrack+1}}/{{globalMergedTracks.length}}</code>
				</h1>
				<button :style="`${(currentMergeTrack+1 === globalMergedTracks.length) ? 'width: 96%' : 'width:48%'}`" class="btn btn-outline-info" @click="currentMergeTrack = parseInt(currentMergeTrack)-1" v-if="currentMergeTrack>0">
					<i class="fas fa-arrow-left"></i>
				</button>
				<button :style="`${(currentMergeTrack === 0) ? 'width: 96%' : 'width:48%'}`" v-if="(globalMergedTracks.length)&&(currentMergeTrack+1)!==globalMergedTracks.length" class="btn btn-outline-info" @click="currentMergeTrack = parseInt(currentMergeTrack)+1">
					<i class="fas fa-arrow-right"></i>
				</button>

				<div style="margin-top:10px;" v-if="currentMergeUuid || numRemovals">
					<code>Merge ID:</code> <br>
					<code>{{currentMergeUuid}}</code>
				</div>

			</div>

			<div v-if="taskUuid === ''" style="display:flex; flex-direction: column; margin-top:10px;">
				<div v-if="datasetsToggled" style="display: flex-wrap; flex-direction: row; width: 95%">
					<code style="padding-top:7px;">Dataset:</code>
					<select v-if="this.taskUuid===''" v-model="currentLocalTrackList" style="width: 95%;" class="form-control">
						<option disabled value="">Datasets Available</option>
						<option v-for="dataset in reidLocalTrackLists" :key="dataset.tableName" :value="dataset.tableName">
							{{ dataset.tableName }}
						</option>
					</select>
				</div>
				<div>
					<button style="width:96%; margin-top:10px;"
						v-if="(taskUuid==='')"
						@click="searchToggled=!searchToggled; datasetsToggled=false"
						class="btn btn-outline-success">
						Search
					</button>
				</div>
				<div>
					<button style="width:96%; margin-top:10px;"
						v-if="(taskUuid==='')"
						@click="mergeListButton=!mergeListButton; datasetsToggled=false"
						class="btn btn-outline-success">
						Merge Track List
					</button>
				</div>
				<div>
					<button style="width:96%; margin-top:10px;"
						v-if="(loadToggled)&&(currentLocalTrackList)&&(taskUuid==='')"
						@click="getMergeUuidsFromReidLocalTrackList(currentLocalTrackList)"
						class="btn btn-success">
						Load
					</button>
				</div>


				<div v-if="searchToggled" id="data-entry-row" style="display:flex;flex-direction:column; width: 96%;height:100%">
					<input v-model="selectedMergeUuid" type="text" placeholder="Search for a Merge Track here" class="form-control">
					<button v-if="selectedMergeUuid" @click="loadMergeUuid(selectedMergeUuid)" class="btn btn-info btn-sm"> Load Merge Track {{selectedMergeUuid}} </button>


					<input placeholder="Go to track" v-model="temporaryMergeUuid" class="form-control">
					<button v-if="temporaryMergeUuid" @click="currentMergeTrack = parseInt(temporaryMergeUuid)-1" class="btn btn-info btn-sm"> Load Index {{temporaryMergeUuid}} </button>

					<input v-model="selectedTaskUuid" type="text" placeholder="Task Uuid" class="form-control">
					<button v-if="selectedTaskUuid" @click="getCleanMergeUuidsFromTaskUuid(selectedTaskUuid)" class="btn btn-info btn-sm"> Load Task {{selectedTaskUuid}} </button>

					<input v-model="selectedMergeLength" type="text" placeholder="Merges of length" class="form-control">
					<button v-if="selectedMergeLength" @click="getMergesOfLength(selectedMergeLength)" class="btn btn-info btn-sm"> Load Merges of length: {{selectedMergeLength}} </button>

					<button @click="getStaffMerges()" class="btn btn-outline-info"> Load Staff Merges </button>
					<button @click="getDeletedMerges()" class="btn btn-outline-info"> Load Deleted Merges </button>

				</div>
			</div>
			<!--
		<button :style="`${(currentMergeTrack === 0) ? 'width: 96%' : 'width:48%'}`" v-if="(globalMergedTracks.length)&&(currentMergeTrack+1)!==globalMergedTracks.length" class="btn btn-outline-info" @click="currentMergeTrack = parseInt(currentMergeTrack)+1">
				<i class="fas fa-arrow-right"></i>
			</button>

		<button :style="" -->

			<!-- <button style="width: 100%" class="fas fa-align-justify"> -->


			<div v-if="!searchToggled && !mergeListButton" style="min-height:200px; height:30%; overflow-y:scroll; margin-top:20px;">
				<ul v-for="(mergeTrack, index) in globalMergedTracks" class="list-group" id="mergeList" :key="mergeTrack">
					<li class="list-group-item d-flex justify-content-between align-items-center" :id="`${(currentMergeTrack===index)?'active':'inactive'}`"
						:style="`border: 3px solid ${(cleanedTracks.includes(mergeTrack)) ? 'green' : 'transparent'}`"
						style="color: white;"
						@click="currentMergeTrack=(index)">
						{{mergeTrack}}
					</li>
				</ul>
			</div>

			<div v-if="globalMergedTracks" class="footer">
				<div v-if="globalMergedTracks.length" style="width: 100%;">
					<button style="width:100%" class="btn btn-outline-success" v-if="localTracks.length>0" @click="postCleanMergeTrack(currentMergeUuid)">
						Submit as Reviewed <i class="icon fas fa-check"></i>
					</button>
					<template v-if="localTracks.length>0 && mergeDataSource==='acceptedMerges'">
						<button v-if="!isStaff " style="width:100%;margin-left: 0px;" class="btn btn-outline-warning"  @click="markAsStaff(currentMergeUuid)">
							Mark as Staff  <i class="icon fas fa-hard-hat"></i>
						</button>
						<button v-else style="width:100%;margin-left: 0px;" class="btn btn-outline-warning" @click="unMarkAsStaff(currentMergeUuid)">
							Remove Staff Label  <i class="icon fas fa-hard-hat"></i>
						</button>
					</template>
				</div>
				<ul class="list-group">
					<li class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid || numRemovals">
							<code>Page number: {{localTracks.map(r=>r['pageNum']).sort((a,b)=>{return a-b})[0]}}</code>
						</div>
					</li>

					<li class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid || numRemovals">
							<code>Number of removals: {{numRemovals}}</code>
						</div>
					</li>

					<li class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid && taskUuid===''">
							<code>Merge Source: {{ mergeDataSource }}</code>
						</div>
					</li>

					<li v-if="advancedFeatures" class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid || numRemovals">
							<code>Marked as Staff: {{isStaff ? 'Yes' : 'No'}} </code>
						</div>
					</li>
				</ul>
				<button :class="`${(batchSelect===false) ? 'btn btn-outline-info' : 'btn btn-warning'}`" @click="batchSelect=!batchSelect" style="width:100%;">
					<i class="icon fas fa-object-group"></i>
				</button>
				<button v-if="batchSelect && selectedLocalTracks.length>0" class="btn btn-outline-info" style="width:100%;margin-left:-1px;" @click="mergeFromBatchSelect()">
					<code>Merge {{selectedLocalTracks.length}}</code>
				</button>
				<button :class="`${(settingsToggle===false) ? 'btn btn-outline-success' : 'btn btn-warning'}`" @click="settingsToggle=!settingsToggle" style="width:100%;">
					<i class="icon fas fa-cogs"></i>
				</button>
				<button class="btn btn-outline-info" @click="toggleImageCardSize" style="width:100%;">
					<i :class="`fas fa-search-${size === 'xl' ? 'minus' : 'plus'}`"/>
				</button>

					
				<template v-if="settingsToggle">
					<div v-if="!this.taskUuid" class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="checkbox1" @click="loadClean=!loadClean">
						<label class="custom-control-label" for="checkbox1">Currently viewing {{loadClean ? 'Reviewed/Clean' : 'Unreviewed'}} Merge Tracks</label>
					</div>

					<div class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="checkbox2" @click="advancedFeatures=!advancedFeatures">
						<label class="custom-control-label" for="checkbox2">Advanced Features are turned {{advancedFeatures ? 'On' : 'Off'}}</label>
					</div>

					<div class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="checkbox3" @click="allowReassignLocalTracks=!allowReassignLocalTracks">
						<label class="custom-control-label" for="checkbox3">Track Reassignment is {{allowReassignLocalTracks ? 'On' : 'Off'}}</label>
					</div>
					<div v-if="isAdmin" class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="showClusteredDetections" @click="toggleClusteredDetections=!toggleClusteredDetections">
						<label class="custom-control-label" for="showClusteredDetections">Clustered Local Tracks are {{toggleClusteredDetections ? 'Shown' : 'Hidden'}}</label>
					</div>
				</template>
				<button v-if="this.taskUuid!==''" class="btn btn-outline-success" @click="finishTask()" style="width:100%;margin-top:100px;">
					Finish Task  <i class="icon fas fa-check"></i>
				</button>
			</div>
		</div>
	</div>

	<div style="display:flex; flex-direction: coloumn; width: 75%;height:100%; margin-left: 12%;">
		
		<div v-if="globalMergedTracks" style="display:flex; flex-direction: column; overflow-y:scroll;height:80vh;">
			<div v-if="selectedLocalTracks.length>0" class="card border-secondary mb-3" style="width: fit-content;margin-bottom:100px;margin-top:100px;">
				<div  style="display: flex; flex-direction: row;" class="local-track-list">
					<div v-for="(localTrackUuid,index) in selectedLocalTracks" :key="index" class="local-track">
						<div class="card border-secondary mb-3" style="width: fit-content;" :aria-hidden="true">
							<div v-for="(detection,index2) in localTrackMap[localTrackUuid]" :key="index2" class="frame-item" :style="setTrackStyleByDetection(detection)" >
								<!-- {{localSetClipCss(subFramesMap.get(detection), sizeMap[detection].width, sizeMap[detection].height)}} -->
								<div style="padding: 20px" v-if="sizeMap[detection]!==undefined">
									

									<img v-onload="framePathsMap.get(detection)" :src="framePathsMap.get(detection)" :style="localSetClipCss(subFramesMap.get(detection), sizeMap[detection].width, sizeMap[detection].height)" @mouseover="setCurrentSubFrame(subFramesMap.get(detection));currentFramePath = framePathsMap.get(detection)">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="(loadMergeThumbnails === false)" class="local-track-list">
				<div v-for="(localTrack) in Object.keys(localTrackMap).map(lt=>{return{localTrackUuid: lt}})" :key="localTrack.localTrackUuid" :class="displayColumns" class="local-track">
					<hr/>

					<b-overlay :show="(pipeLineProgress['frames'] !== true)" variant="transparent" blur= "5px" rounded="sm" >
						<div class="card border-secondary mb-3" style="width: fit-content;" :aria-hidden="true" @click="handleLocalTrackClick(localTrack)"
						>
							<template v-if="!removalList.map(r=>r.localTrackUuid).includes(localTrack.localTrackUuid)">
								<!-- Local track actions -->
								<div class="card-header" :style="`${selectedLocalTracks.includes(localTrack.localTrackUuid) ? 'background-color: red;' : ''}`">
									<pre class="foo" v-if="advancedFeatures">Local Track: {{localTrack.localTrackUuid}}</pre>

									<!-- Simplified UI for supahands -->
									<template v-if="(!batchSelect) && (organisation === 'supahands')">
										<button style="float:left" class="btn btn-outline-warning" @click="removeLocalTrackFromMergeTrack(localTrack, 'error')">
											<i class="fas fa-user-times"></i>
										</button>
									</template>

									
									<template v-else>
										
										<!-- Disabling track swap for supahands temporarily as theft pipeline data should not have track swaps removed -->
										<button
											v-if="localTrackMap[localTrack.localTrackUuid].length>1 && (!batchSelect) && (organisation!=='supahands')"
											style="float:left;"
											@click="removeLocalTrackFromMergeTrack(localTrack, 'trackSwap')"
											class="btn btn-outline-warning">
											<i class="fas fa-user-friends"></i>
										</button>
										<!-- Adding trash action for review agents to handle reid agents not properly trashing images -->
										<button
											v-if="(!batchSelect) && mergeDataSource ==='acceptedMerges'"
											style="float:left;"
											@click="moveLocalTrackToTrash(localTrack)"
											:id="`add-to-trash-${localTrack.localTrackUuid}`"
											class="btn btn-outline-warning">
											<i class="fas fa-trash"></i>
											<b-tooltip :target="`add-to-trash-${localTrack.localTrackUuid}`" triggers="hover">
												Move image into the trash folder (only for images of non humans)
											</b-tooltip>
										</button>
										
										<template v-if="!batchSelect && dataBuffer[currentMergeUuid].localTracks.length>=1">
											<button 
												v-if="mergeDataSource==='acceptedMerges'" 
												:id="`remove-from-merge-track-${localTrack.localTrackUuid}`"
												style="float:left;" 
												@click="removeLocalTrackFromMergeTrack(localTrack, 'error')" 
												class="btn btn-outline-warning"
											>
												<i class="fas fa-user-times"></i>
												<b-tooltip :target="`remove-from-merge-track-${localTrack.localTrackUuid}`" triggers="hover">
													Remove image from merge track
												</b-tooltip>
											</button>
											<button 
												v-if="mergeDataSource==='garbage'"
												:id="`remove-from-trash-${localTrack.localTrackUuid}`" 
												style="float:left;" @click="removeLocalTrackFromTrash(localTrack)" 
												class="btn btn-outline-success"
											>
												<i class="fas fa-user-plus" v-b-tooltip.hover="'Move image out of trash folder'"></i>
												<b-tooltip :target="`remove-from-trash-${localTrack.localTrackUuid}`" triggers="hover">
													Move image out of trash folder
												</b-tooltip>
											</button>
										</template>
										<button 
											v-if="allowReassignLocalTracks" 
											style="float:left" 
											v-b-modal.modal-reassign
											class="btn btn-outline-warning"
											@mousedown="activeLocalTrack=localTrack"
										>
											<i class="fas fa-user-edit"></i>
										</button>
										<!-- Shows only trash icon when merge is only of length one to avoid agents from destroying mergeUuids as a result of removing a single remaining local track -->
										
										
									</template>
								</div>

								<!-- Local track image -->
								<div class="card-body">
									<div v-for="(detection,index2) in localTrackMap[localTrack.localTrackUuid]" :key="index2" :class="`frame-item ${(currentLocalTrackExpended===localTrack.localTrackUuid)?'expanded':''}`" :style="setTrackStyleByDetection(detection, localTrack.localTrackUuid)">
										<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
											<img
												v-if="sizeMap[detection]!==undefined"
												@click="detectionSelected(localTrack.localTrackUuid, detection)"
												@mouseover="setCurrentSubFrame(subFramesMap.get(detection));currentFramePath = framePathsMap.get(detection);currentDetectionUuid = detection;"
												:src="framePathsMap.get(detection)"
												:style="localSetClipCss(subFramesMap.get(detection), sizeMap[detection].width, sizeMap[detection].height)"
											>
										</div>
									</div>
								</div>
							</template>
						</div>
					</b-overlay>

				</div>
				<vue-context ref="menu">
					<li>
						<a href="#" @click="showFullFrame()">View Full Frame</a>
					</li>
				</vue-context>
			</div>
		</div>
		

	</div>


	<ReassignmentModal id="modal-reassign"
		:localTrack="activeLocalTrack"
		:localTrackDetections="localTrackMap[(activeLocalTrack || {}).localTrackUuid]"
		:subFrames="Object.fromEntries(subFramesMap)"
		:framePaths="Object.fromEntries(framePathsMap)"
		:databaseName="db"
		:datasetName="currentLocalTrackList"
		@reassign="UpdateAfterReassignment"
	/>
</div>
</template>


<script>
import VueContext from 'vue-context';

import { dataAPI, mlserver } from "../http-common";
import {setClipCss, createImageSizeMap, scaleSubframeBoundsToDisplay} from '../utils/images.js';
import Vue from 'vue';
import { BOverlay, BTooltip, VBModal, BModal } from 'bootstrap-vue'
Vue.component('b-overlay', BOverlay)
Vue.component('b-tooltip', BTooltip)
Vue.component('b-modal', BModal);

import ReassignmentModal from '@/components/review/ReassignmentModal.vue';
import FullFrameDisplay from '@/components/tools/FullFrameDisplay.vue';

export default {
	name: 'MergeTrackReviewer',

	props: {

		taskUuid: {type: String, default: ''},
	},

	components: {
		BOverlay,
		ReassignmentModal,
		FullFrameDisplay,
		VueContext,
	},

	directives: {
		'b-modal': VBModal
	},

	data: function() {
		return {
			activeLocalTrack: null,
			pipeLineProgress: {},
			loadClean: false,
			isLoading: false,
			settingsToggle: false,
			log: ["Not loading","Retrieving mergeUuids", "Retrieving localtracks", "Retrieving detections", "Retrieving subframes", "Retrieving frames", "Done"],
			framePathsMap: new Map(),
			recentlyMerged: [],
			globalMergedTracks: null,
			subFramesMap: new Map(),
			loadMergeThumbnails: false,
			username: '',
			advancedActive: false,
			allowReassignLocalTracks: false,
			advancedUsers : ["jonas", "kokkonen"],
			currentMergeTrack: 0,
			localTracks: [],
			workingLocalTracks: [],
			currentId: "",
			detectionData: [],
			selectedTaskUuid: null,
			mergeToDetectionMap: null,
			advancedFeatures: false,
			mergeSubFrameMap: null,
			mergeFrameMap: null ,
			finderToggle: false,
			localTrackMap: {},
			displayColumnOptions: ['col2','col3','col4','col5'],
			displayColumns : 'col5',
			unassignedLocalTracks:[],
			unassignedSubFramesMap: null,
			unassignedFramesPathsMap: null,
			unassignedLocalTrackMap: {},

			unassignedLocalTrackDetections:null,
			selectedYear: null,
			selectedMonth: null,
			selectedDay: null,
			timestampRange: null,
			currentFramePath: null,
			loaded: false,
			dataBuffer: {},
			temporaryMergeUuid: "",
			currentSubFrame: null,
			currentLocalTrack: 0,
			mergePathScoreArray: [],
			mldone: false,
			currentMergeUuidAssign: "",
			selectedMergeUuid: '',
			startDate: "",
			endDate: "",
			randomDetectionEachMerge: [],
			randomMergeSubFramesMap: null,
			detectionUuidForLocalTrack: null,
			databasesAvailable: [],
			databaseSelected: "",
			frameWidth: "300px",
			frameDistanceMatrix: [],
			segmentedImages: [],
			threshold: .9,
			localTrackMatchOrder: null,
			detectionUuidtoLocalTrackUuidMap: null,
			experimentalFeatures: false,
			currentLocalTrackList: "",
			reidLocalTrackLists: null,
			timeTest: null,
			numRemovals: 0,
			trackSwaps: 0,
			localTrackInfoMap: null,
			localTracksLoaded: false,
			highResDatasetsDate: '2020-06-12',
			removalList: null,
			selectedLocalTracks: [],
			infoToggled: false,
			batchSelect: false,
			datasetsToggled: true,
			searchToggled: false,
			loadToggled: true,
			selectedMergeLength: null,
			mergeListButton: false,
			cleanedTracks: [],
			toggleClusteredDetections: false,
			selectedDetections: {},
			localTrackExpansion: {},
			currentLocalTrackExpended: '',
			db: "",
			currentSubFrameCss: "",
			sizeMap: {},
			currentDetectionUuid: "",
			currentSubFrameCss: "",
			size: 'xs',
			mergeDataSource: "acceptedMerges",
			fullFramePathSizeMap: {},
			currentFullFrameSubFrame: {},
			currentFullFrameImage: null,
			portalDetails: null
		}
	},

	computed: {
		resolution: function(){
			return 1920;
		},
		format: function(){
			if(this.currentLocalTrackList.replace(/-/g,'') === '20200629'){
				return 'webp';
			}
			else{
				return 'jpeg';
			}
		},


		currentMergeUuid: function(){return this.globalMergedTracks[this.currentMergeTrack]},
		isStaff() { return this.localTracks[0].isStaff === 'staff' },
		isLoggedIn: function(){return this.$store.getters.isLoggedIn},
		correctImages: function(){
			if (this.experimentalFeatures){
				let largestSoFar = 0;
				let indexOfLargestBucket = null;
				const segmentedImages = this.segmentedImages;

				segmentedImages.forEach((bucketArray, index)=>{ // find which bucket is largest, and therefore has most images.
					if(bucketArray.length > largestSoFar){
						largestSoFar = bucketArray.length;
						indexOfLargestBucket = index;
					}
				})

				const flatcorrectImages = segmentedImages.slice(indexOfLargestBucket, 1).flat(); // remove the largest bucket

				return flatcorrectImages
			}
		},
		correctImagesMap: function(index) {
			if (this.experimentalFeatures){
				// map from Uuid to index of frame.
				const correctImagesMap = new Map();
				this.detectionData.forEach((detection, index)=>{
					correctImagesMap.set(detection.detectionsUuid, index)
				})
				return correctImagesMap
			}
		},
		organisation: function(){
			return localStorage.getItem('organisation');
		},
		isAdmin(){
			return (localStorage.getItem('is_admin') === 'true');
		},
		imageCardSize(){
			
			if(this.size === 'xl'){
				const width = 150;
				return {
					fixedCropWidth: width,
					css: `width: ${width}px; height: ${width*1.7}px;`
				};
			}
			else{
				const width = 75;
				return {
					fixedCropWidth: 75,
					css: `width: ${width}px;`
				};
			}
		},
		hasPreCroppedFrames(){
			return this.dataBuffer[this.currentMergeUuid].hasPreCroppedFrames;
		}
	},

	watch: {
		currentMergeTrack: function() {
			this.getMergeUuidData(this.currentMergeUuid)
			//reset number of local track removals
			this.selectedLocalTracks = [];
			this.numRemovals = 0;
			this.trackSwaps = 0;
		},
		databaseSelected(newVal) {
			localStorage.setItem("database", JSON.stringify(newVal));
			this.databaseSelected = newVal;

			this.$router.push({ name: "reid-review", params: { db: newVal } });
		},
		frameWidth(newVal){
			this.currentSubFrameCss = this.setFrameRegionCss(this.getCurrentSubFrame(), true, this.currentDetectionUuid);
		},
		batchSelect(newVal){
			if (newVal === false) {this.selectedLocalTracks=[]}
		},
		currentLocalTrackList(newVal){
			const { dbName, datasetConfig } = this.reidLocalTrackLists.filter(r=>r.tableName === newVal)[0];
			if(datasetConfig?.portal_ks_config && datasetConfig){
				const portalDetails = datasetConfig['portal_ks_config'];
				this.portalDetails = { datasetName: portalDetails.dataset_name, deploymentCode: portalDetails.deployment_code };
			}

			this.db = dbName;
			this.loadToggled=true;
		},
		loadClean(newVal){
			this.loadToggled=true;
		},
		toggleClusteredDetections(newVal){
			this.getMergeUuidData(this.currentMergeUuid, true);
		}
	},

	mounted: function() {
		if(this.taskUuid!==''){
			window.addEventListener('keydown', this.keyPressEventHandler, false);
			if (localStorage.getItem('username') !== undefined) {
				this.username = localStorage.getItem('username');
			}
			// getting only task information that is required rather than getting all then filtering
			const url = `/getTaskInfo/${this.taskUuid}`;
			dataAPI
				.get(url, {taskUuid: this.taskUuid})
				.then(async response => {
					

					this.db = response.data.databaseName.split('_')[0];
					const { data: { datasetConfig } } = await dataAPI.post(`${this.db}/datasets/getDataset`, {localTrackList: response.data['tableName']})
					
					if(datasetConfig?.portal_ks_config){
						const {deployment_code: portalDeploymentCode, dataset_name: portalDatasetName } = datasetConfig.portal_ks_config;
						this.portalDetails = {
							deploymentCode: portalDeploymentCode,
							datasetName: portalDatasetName
						}
					}

					this.currentLocalTrackList = response.data['tableName'];
					this.getMergeUuidsFromTaskUuid(this.taskUuid);
				})
				.catch(e=>{
					console.log(e);
				});
		}
		else{
			window.addEventListener('keydown', this.keyPressEventHandler, false);
			this.retrieveActiveReidDatasets();
			// Retrieve username
			if (localStorage.getItem('username') !== undefined) {
				this.username = localStorage.getItem('username');
			}
		}
	},
	methods: {
		async checkMergeTrack(mergeUuid){
			const { data } = await dataAPI.post(`/${this.db}/getMergeTrackInfo`, { mergeUuid });
			
			const mergeTrackContents = data.mergeInfo;

			if(mergeTrackContents.length){
				return true;
			}
			else{
				return false;
			}
		},
		handleInvalidMergeTrack(invalidMergeUuid){
			// prevent blocking annotator from progressing through task
			this.globalMergedTracks = this.globalMergedTracks.filter(mergeUuid => mergeUuid !== invalidMergeUuid);
			// reset to first merge track in list
			this.currentMergeTrack = 0;
		},
		retryImage(event){
			// let image = new Image();
			console.log('failed',event)
			// image.src = imgPath;
		},
		retrieveActiveReidDatasets: function(){

			dataAPI
				.get(`/datasets/getActiveDatasets`)
				.then(response => {
					console.log(`Retrieving all local track lists from database ... ${response.data.length} lists returned`);
					this.reidLocalTrackLists = response.data;

				})
				.catch(e =>{
					console.log(e);
					this.$noty.error(`Error retrieving datasets: ${e}`,{layout:  "bottomLeft"})
				})
		},
		tableNameToDate(tableName){
			const yyyymmdd = tableName.replace(/reidlocaltracks/g,'');
			return yyyymmdd.slice(0,4)+"-"+yyyymmdd.slice(4,6)+"-"+yyyymmdd.slice(6,8);
		},
		/* Server access functions for "Removal of local tracks from mergeUuids"*/
		keyPressEventHandler: function(event){
			if ((event.key === "g")){
				if(this.frameWidth === "700px"){
					this.frameWidth = '300px';
				} else if(this.frameWidth === "300px"){
					this.frameWidth = '700px';
				}
			}
		},
		logout: function () {
			this.$store.dispatch('logout')
				.then(() => {
					this.$router.push('/')
				})
		},
		getCurrentSubFrame: function(){
			if (this.currentSubFrame !== null){
				return this.currentSubFrame;
			}
		},
		setCurrentSubFrame: function(currentSubFrame){
			this.currentSubFrame = currentSubFrame;
		},
		postCleanMergeTrack: function(){
			let confirm  = window.confirm('Submit this Merge Track as clean?');

			if(confirm){
				const url = `${this.db}/review/submitCleanMergeTrack/mergeUuid/${this.currentMergeUuid}`;
				const body = {
					reidTableName: this.currentLocalTrackList,
					errorRemovals: this.numRemovals,
					trackSwapRemovals: this.trackSwaps,
					labelledBy: '',
					reviewedBy: this.username,
					status: 'reviewed'
				}
				dataAPI
					.post(url, body)
					.then(response=>{
						// go to next merge track
						if(this.currentMergeTrack+1 >= this.globalMergedTracks.length){
							this.finishTask();
						}
						else{
							this.cleanedTracks.push(this.currentMergeUuid)
							this.$noty.success(`Submitted merge track ${this.currentMergeUuid} as reviewed/clean`, {layout: 'bottomLeft', theme: 'sunset'})
							this.currentMergeTrack = parseInt(this.currentMergeTrack)+1;
						}
					}).catch(error=>{
						this.$noty.error(`Could not submit merge track as reviewed/clean: ${error}`);
						console.log(error);
					});
			}
		},
		setFrameRegionCss: function(coords, visible, detectionUuid) {

			if (coords !== null){

				const visVal = visible ? 'visible' : 'hidden';

				const displayWidth = $(".ff-canvas_fullframe_preview").width();
				const displayHeight =$(".ff-canvas_fullframe_preview").height();

				const scale = scaleSubframeBoundsToDisplay(coords, this.sizeMap[detectionUuid].width, this.sizeMap[detectionUuid].height, displayWidth, displayHeight);

				const borderPx = '2';
				const color = 'white';

				return `position:absolute;
						top:${scale.top}px;
						left:${scale.left}px;
						height:${scale.height}px;
						width:${scale.width}px;
						border:${borderPx}px solid ${color};
						color:${color};
						visibility:${visVal};
						z-index: 100`;
			}

		},
		async markAsStaff(mergeUuid){
			let confirm  = window.confirm('Confirm to mark this Merge Track as staff');

			if(confirm){
				const url = `${this.db}/review/markAsStaff/mergeUuid/${mergeUuid}`;
				try{
					const { data } = await dataAPI.get(url);
					this.getMergeUuidData(mergeUuid, true);
					this.$noty.success('Marked track as staff',{layout:"bottomLeft"});
				}
				catch(error){
					this.$noty.success(`Failed to mark track as staff ${error}`,{layout:"bottomLeft"})
				}
			}
		},
		async unMarkAsStaff(mergeUuid){
			let confirm  = window.confirm('Confirm to remove staff label?');

			if(confirm){
				const url = `${this.db}/review/unMarkAsStaff/mergeUuid/${mergeUuid}`;
				try{
					const { data } = await dataAPI.get(url);
					this.getMergeUuidData(mergeUuid, true);
					this.$noty.success('Removed staff label!', {layout:"bottomLeft"});
				}
				catch(error){
					this.$noty.success(`Failed to remove staff label! ${error}`,{layout:"bottomLeft"})
				}
			}
		},
		finishTask(){
			let confirm  = window.confirm('Submit Task as finished?');
			if (confirm){
				dataAPI.post(`/updateTask`,{status: "pendingApproval", taskUuid: this.taskUuid, taskType: 'review'}).then(response=>{
					const nextUrl = window.location.href.split('/')[0]+"/"+window.location.href.split('/')[1];

					window.location.replace(`${nextUrl}`+'dashboard');
				})
			}
		},
		mergeUuidUpdatePipeline: async function (mergeUuid, type='acceptedMerges'){
			
			this.currentFramePath=null;
			this.pipeLineProgress['frames'] = false;
			this.$root.$emit('setIsLoading', true);
			this.selectedLocalTracks = [];
			let removalList=[];
			let detectionData;
			let localTracks;
			const isValidMerge = await this.checkMergeTrack(mergeUuid);
			if(!isValidMerge){
				this.$noty.info("Removing empty merge track", {layout: "bottomLeft"});
				this.handleInvalidMergeTrack(mergeUuid);
				return;
			}
			// data loading pipeline
			let { data, updateMerge, mergeUuidUpdated } = (type === 'acceptedMerges') ? (await this.getLocalTracksForMergeUuid(mergeUuid)) : (await this.getLocalTracksForDeletedMergeUuid(mergeUuid));

			const isReducedDataset = (data[0]['isClusterDetection']!==null);
			if(!isReducedDataset){
				localTracks = data;
			}
			else{
				localTracks = (this.toggleClusteredDetections) ? data.filter(r=>r.isClusterDetection === 'true') : data;
			}

			if(updateMerge){
				mergeUuid = mergeUuidUpdated;
			}

			this.pipeLineProgress['localtracks'] = true;

			detectionData = (type === 'acceptedMerges')  ? (await this.getDetectionsForMergeUuid(mergeUuid)).data: localTracks.sort((a,b)=>a.timestamp-b.timestamp);
			const hasPreCroppedFrames = Object.keys(detectionData[0]).includes('framePathCropped');
			detectionData.sort((a,b)=>a.timestamp-b.timestamp);
			// Handles non clustered datasets and allows toggling hidden detections
			if(!isReducedDataset){
				detectionData = detectionData;
			}
			else{
				detectionData = (this.toggleClusteredDetections) ? data.filter(r=>r.isClusterDetection === 'true') : data;
			}

			this.pipeLineProgress['detections'] = true;
			const detectionArray = detectionData.map(point => point.detectionUuid);
			
			let detectionArray2;
			
			if(hasPreCroppedFrames){
				detectionArray2 = detectionData.map(point => {return {detectionUuid: point.detectionUuid, framePath: point.framePath, framePathCropped: point?.framePathCropped}});
			}
			else{
				detectionArray2 = detectionData.map(point => {return {detectionUuid: point.detectionUuid, framePath: point.framePath }});
			}

			const detectionArrayPromises = await Promise.all([this.retrieveDetectionSubFramesForMerge(detectionArray), this.retrieveFullFramesForMergeUuid(detectionArray2)]);
			const localTrackMap = this.createLocalTrackMap(detectionData);
			const localTrackInfoMap = this.createLocalTrackInfoMap(localTracks);
			const subFramesMap = this.createSubFramesMap(detectionArrayPromises[0].data);

			const framePathsMap = this.createFramePathMap(detectionArrayPromises[1]);
			const sizeMap = await createImageSizeMap(framePathsMap);

			this.sizeMap = {...this.sizeMap, ...sizeMap};

			this.pipeLineProgress['subframes'] = true;
			this.pipeLineProgress['frames'] = true;

			
			// console.log("detectionData", detectionData)
			const dataForMergeUuid = { // All the data that this function stores and needs to use
				mergeUuid,
				localTracks,
				removalList,
				detectionData,
				localTrackMap,
				subFramesMap,
				framePathsMap,
				detectionArray,
				localTrackInfoMap,
				hasPreCroppedFrames
			}

			this.isLoading=false;
			this.$root.$emit('setIsLoading', false);
			return dataForMergeUuid;
		},
		getMergeUuidData: async function(mergeUuid, overWrite=false){
			const type = this.mergeDataSource;
			let check = (mergeUuid in this.dataBuffer); // ** more complicated check, need to validate that all the data is present. *this will handle when the data isn't fully loaded and adds a key to the dataBuffer
			if(overWrite){
				check = false;
			}
			// Overwrite when reloading same mergeuuid from toggling hidden detections
			if (check){ // check if mergeUuid has already been loaded
				this.pipeLineProgress['frames']=true;
				// set data objects from dataBuffer
				this.localTracks = this.dataBuffer[mergeUuid].localTracks
				this.detectionData = this.dataBuffer[mergeUuid].detectionData
				this.localTrackMap = this.dataBuffer[mergeUuid].localTrackMap
				this.framePathsMap = this.dataBuffer[mergeUuid].framePathsMap
				this.subFramesMap = this.dataBuffer[mergeUuid].subFramesMap
				this.frameDistanceMatrix = this.dataBuffer[mergeUuid].frameDistanceMatrix
				this.segmentedImages = this.dataBuffer[mergeUuid].segmentedImages

			} else { // if not loaded
				let dataLoad = await this.mergeUuidUpdatePipeline(mergeUuid, type) // load data
				// allocate data to local variables
				this.localTracks = dataLoad.localTracks
				this.detectionData = dataLoad.detectionData
				this.localTrackMap = dataLoad.localTrackMap
				this.framePathsMap = dataLoad.framePathsMap
				this.subFramesMap = dataLoad.subFramesMap
				this.removalList=dataLoad.removalList;
				this.frameDistanceMatrix = dataLoad.frameDistanceMatrix
				this.segmentedImages = dataLoad.segmentedImages
				this.localTrackInfoMap = dataLoad.localTrackInfoMap;
				if (this.experimentalFeatures) {
					const testVar = await this.dataLoadPipelineForLocalTrackAllocation();
				}
				// technically don't need a check as the data will eventually reach the database as they are chained promises.
				if (dataLoad){
					// Store the data in the dataBuffer
					this.dataBuffer[mergeUuid] = dataLoad;
				} else {
					console.log('Data not writen as mergeUuidUpdatePipeline function did not return data')
				}
			}
		},
		getMergeUuidsFromReidLocalTrackList: function(selectedLocalTrackList){
			this.loadToggled=false;
			this.pipeLineProgress={};
			this.isLoading=true;
			this.mergeDataSource = 'acceptedMerges';
			let _this = this;
			if(this.loadClean===false){
				const url = `/${this.db}/review/getMergeUuids/${selectedLocalTrackList}`;
				return dataAPI
					.get(url)
					.then(response => {

						if(response.data.length>0){this.globalMergedTracks = response.data.map(mergeTrack => mergeTrack.mergeUuid);
							this.pipeLineProgress['mergeUuids'] = true;
							this.getMergeUuidData(this.currentMergeUuid);
						}
						else{
							this.$noty.error(`No dirty merges to load `, {layout:'center',killer:true});
							this.globalMergedTracks=[];
						}
					})
					.catch(e => {
						console.log(e);
					});
			}
			else{
				const url = `/${this.db}/review/getCleanMergeUuidsFromLocalTrackList/${selectedLocalTrackList}`;
				return dataAPI
					.get(url)
					.then(response => {


						if(this.loadMergeThumbnails === false){
							this.globalMergedTracks = response.data.map(mergeTrack => mergeTrack.mergeUuid);
							this.pipeLineProgress['mergeUuids'] = true;

							this.getMergeUuidData(this.currentMergeUuid);
						}
						else{
							this.globalMergedTracks = response.data.map(mergeTrack => mergeTrack.mergeUuid);
							this.pipeLineProgress['mergeUuids'] = true;
							this.getMergeUuidData(this.currentMergeUuid);
						}
					})
					.catch(e => {
						console.log(e);
					});
			}
		},
		getCleanMergeUuidsFromTaskUuid(taskUuid){
			const url = `/${this.db}/review/getMergeUuidsFromReviewTask/`;
			return dataAPI
				.post(url, {taskUuid: taskUuid})
				.then(response => {

					this.globalMergedTracks = response.data.map(mergeTrack => mergeTrack.mergeUuid);
					this.currentLocalTrackList = response.data[0]['reidTableName'].slice(15,19)+response.data[0]['reidTableName'].slice(19,21)+response.data[0]['reidTableName'].slice(21,23);
					this.pipeLineProgress['mergeUuids'] = true;
					this.getMergeUuidData(this.currentMergeUuid);
				})
				.catch(e => {
					console.log(e);
				});

		},
		getMergeUuidsFromTaskUuid: function(taskUuid){
			this.pipeLineProgress={};
			this.isLoading=true;
			this.$root.$emit('setIsLoading', true);
			const url = `/review/getMergeUuidsFromTask/taskUuid/${taskUuid}`;
			return dataAPI
				.get(url)
				.then(async response => {
					this.$root.$emit('setIsLoading', false);
					this.globalMergedTracks = response.data.map(r=>r.mergeUuid);
					this.pipeLineProgress['mergeUuids'] = true;
					this.getMergeUuidData(this.currentMergeUuid);
				})
				.catch(e => {
					console.log(e);
				});
		},
		loadMergeUuid: function(mergeUuid){
			this.getMergeUuidData(mergeUuid);
			this.globalMergedTracks = [mergeUuid];
		},
		getLocalTracksForMergeUuid: function(mergeUuid){
			
			return dataAPI
				.post(`/${this.db}/getMergeTrackInfo`,{mergeUuid: mergeUuid})
				.then(response => {
					if(response.data.mergeInfo.length === 0){
						return dataAPI
							.get(`/${this.db}/detections/mergeUuidLocalTracks`,{params: {mergeUuid: mergeUuid}})
							.then(response2 => {
								console.log('getLocalTracksForMergeUuid corrupted cache', {data: response2.data, updateMerge: false, mergeUuidUpdated: mergeUuid})
								return {data: response2.data, updateMerge: false, mergeUuidUpdated: mergeUuid}
							})
							.catch(e => {
								console.log(e);
							});
					}
					else if(mergeUuid === response.data.mergeUuid){
						console.log(`get local tracks for specific mergeUuid: `, mergeUuid, response.data);
						console.log('getLocalTracksForMergeUuid unchanged mergeUuid', {data: response.data.mergeInfo, updateMerge: false, mergeUuidUpdated: mergeUuid})
						return {data: response.data.mergeInfo, updateMerge: false, mergeUuidUpdated: mergeUuid}
					}
					else{
						const indexToReplace = this.globalMergedTracks.findIndex(mergeUuid=>mergeUuid === mergeUuid);
						this.globalMergedTracks[indexToReplace] = response.data.mergeUuid;
						console.log('getLocalTracksForMergeUuid changed mergeUuid', {data: response.data.mergeInfo,updateMerge: true, mergeUuidUpdated: response.data.mergeUuid});
						return {data: response.data.mergeInfo,updateMerge: true, mergeUuidUpdated: response.data.mergeUuid};
					}
				})
				.catch(e => {
					console.log(e);
				});
		},
		async getLocalTracksForDeletedMergeUuid(mergeUuid){
			try{	
				const { data } = await dataAPI.post(`/${this.db}/garbage/detections/mt-uuid/${mergeUuid}`, { datasetName: this.currentLocalTrackList });
				return { data, updateMerge: false};
			}
			catch(error){
				console.error("In: getLocalTracksForDeletedMergeUuid", error);
				this.$noty.error(`Failed to get images for deleted merge ${mergeUuid}`)
			}
		},
		async retrieveFramePaths(detections, cropped, datasetName, withSubframes=false){
			try{
				const portalDetails = this.portalDetails??{};
				const { data } = await dataAPI.post(`${this.db}/frames/frameDetails`, { detections, cropped, datasetName, withSubframes, portalDetails });
				return data;
			}
			catch(error){
				console.error(error);
				this.$noty.error("Failed to retrieve image urls");
			}
		},
		async retrieveFullFramesForMergeUuid(detections) {
			const hasPreCroppedFrames = Object.keys(detections[0]).includes("framePathCropped");
			const framePaths = await this.retrieveFramePaths(detections, hasPreCroppedFrames, this.currentLocalTrackList);
			return framePaths;
		},
		retrieveFullFramesForUnassignedLocalTracks: function(detections) {
			console.log(`Retrieving frame paths for unassigned local track detections...`);
			return server
				.post(`/frame/get/detections/rgb/jpg/${this.db}`, {detections: detections})
				.then(response => {
					return response
				})
				.catch(e => {
					console.log(e);
					this.framePathsMap = null;
				});
		},
		getDetectionsForMergeUuid: function(mergeUuid) {
			return dataAPI
				.post(`/${this.db}/detections/mt-uuid/${mergeUuid}`, {datasetName: this.currentLocalTrackList})
				.then((response)=>{
					return response})
				.catch(e => {
					console.log(e)});
		},
		retrieveDetectionSubFramesForMerge: function(detectionUUIDs) {
			return dataAPI
				.post(`/${this.db}/detections/subframes/`, { detections: detectionUUIDs, reidInput: 'reidInput_'+this.currentLocalTrackList})
				.catch(e => {
					console.log(e);
				})
		},
		removeLocalTrackFromMergeTrack: async function(localTrack, type){
			const mergeUuid = this.currentMergeUuid;
			// quality of life, make sure state of merge uuid change is reflected when switching merges and switching back
			try{
				const body = {
					localTrackUuid: localTrack.localTrackUuid,
					requiresConsolidation: 'requiresConsolidation_from_review'
				}

				const result = (await dataAPI.post(`${this.db}/updateLocalTrackMergeUuid`,body)).data;
				this.$delete(this.localTrackMap, localTrack.localTrackUuid);
			}
			catch(error){
				this.$noty.error(`Failed to move ${localTrack.localTrackUuid} to new mergeUuid`)
			}
		},

		async removeLocalTrackFromTrash(localTrack){
			try{
				const body = {
					localTrack: localTrack,
					dataset: this.currentLocalTrackList
				}

				await dataAPI.post(`${this.db}/garbage/remove/localTrack`, body);
				this.$delete(this.localTrackMap, localTrack.localTrackUuid);
			}
			catch(error){
				this.$noty.error(`Failed to move ${localTrack.localTrackUuid} to new mergeUuid`)
			}
		},
		retrieveDetectionSubFramesForUnassignedLocalTracks: function(detectionArray) { // **

			const detections = detectionArray.map(point => point.detectionsUuid)
				.map(uuid => `'${uuid}'`)
				.toString();

			return dataAPI
				.post(`/${this.db}/detections/subframes/`, { detections: detections })
				.then(response => {

					return response
				})
				.catch(e => {
					console.log(e);
					this.unassignedSubFramesMap = null;
				});
		},

		/* Helper functions */
		createFramePathMap: function(rawData) {
			let _framePathsMap = new Map();

			rawData.forEach(path => _framePathsMap.set(path.detectionUuid, path.frame));
			if (this.recentlyMerged !== null){
				this.recentlyMerged.forEach(entry => {
					_framePathsMap.set(entry.firstDetectionUuid, entry.thumbNail);
				});
			}


			return _framePathsMap;
		},
		createSubFramesMap: function(rawData) {
			let _subFramesMap = new Map();
			rawData
				.filter(subFrame => subFrame.label === 'person' && subFrame.textureLabel === 'rgb')
				.forEach(subFrame => _subFramesMap.set(subFrame.detectionUuid, {
					top: subFrame.minimumY,
					right: subFrame.maximumX,
					bottom: subFrame.maximumY,
					left: subFrame.minimumX
				}));

			return _subFramesMap;
		},
		createLocalTrackMap: function(detections){
			let localTrackMap = {}

			detections.forEach(detection => { //create detections association
				let localTrack = detection.localTrackUuid;
				let detectionUuid = detection.detectionUuid;
				if (!(localTrack in localTrackMap)) { // if local track no in object
					localTrackMap[localTrack] = [detectionUuid]; // if not in object create new object key and value pair
				} else {
					localTrackMap[localTrack].push(detectionUuid); // if in object add to list at the end
				}
			});

			let localTrackIndexes = Object.keys(localTrackMap); // get keys

			// only select up to 5 frames for each localTrack
			localTrackIndexes.forEach((localTrack, index) => {

				let arrayOfDetectionUuids = localTrackMap[localTrack];

				let length = arrayOfDetectionUuids.length;

				let numberOfFrames = 10;

				let numberOfBlocks = Math.ceil(length/numberOfFrames);

				let newArray = [];

				for(let i = 0; i<length; i+=numberOfBlocks) {
					newArray.push(arrayOfDetectionUuids[i]);
				}

				localTrackMap[localTrack] = newArray; // assign new Array to localTrackMap
			});
			return localTrackMap
		},
		createLocalTrackInfoMap(localTracksInfoObj){
			const map = new Map();

			localTracksInfoObj.forEach(localTrack => {
				map.set(localTrack.localTrackUuid,{
					taskUuid: localTrack.taskUuid,
					detectionUuid: localTrack.detectionUuid,
					trackUuid: localTrack.trackUuid,
					user: localTrack.user,
					pageNum: localTrack.pageNum,
					mergeUuid: localTrack.mergeUuid
				})
			})
			return map;

		},
		createUnassignedLocalTrackMap: function(detections){
			let localTrackMap = {}

			detections.forEach(detection => { //create detections association
				let localTrack = detection.localTrackUuid;
				let detectionUuid = detection.detectionsUuid;
				if (!(localTrack in localTrackMap)) { // if local track no in object
					localTrackMap[localTrack] = [detectionUuid]; // if not in object create new object key and value pair
				} else {
					localTrackMap[localTrack].push(detectionUuid); // if in object add to list at the end
				}
			});

			let localTrackIndexes = Object.keys(localTrackMap); // get keys

			// only select up to 5 frames for each localTrack
			localTrackIndexes.forEach((localTrack, index) => {

				let arrayOfDetectionUuids = localTrackMap[localTrack];

				let length = arrayOfDetectionUuids.length;

				let numberOfFrames = 10;

				let numberOfBlocks = Math.ceil(length/numberOfFrames);

				let newArray = [];

				for(let i = 0; i<length; i+=numberOfBlocks) {

					newArray.push(arrayOfDetectionUuids[i]);
				}

				localTrackMap[localTrack] = newArray; // assign new Array to localTrackMap
			});

			// ** need to reduce scope of this so that it only selects max 5.
			return localTrackMap
		},
		localSetClipCss: function(coords, width, height) {

			return setClipCss(coords, width, height, this.imageCardSize.fixedCropWidth, this.hasPreCroppedFrames);

		},
		copyStringToClipboard: function (str) {
			// from https://techoverflow.net/2018/03/30/copying-strings-to-the-clipboard-using-pure-javascript/
			// Create new element
			let el = document.createElement('textarea');
			// Set value (string to be copied)
			el.value = str;
			// Set non-editable to avoid focus and move outside of view
			el.setAttribute('readonly', '');
			el.style = {position: 'absolute', left: '-9999px'};
			document.body.appendChild(el);
			// Select text inside element
			el.select();
			// Copy text to clipboard
			document.execCommand('copy');
			// Remove temporary element
			document.body.removeChild(el);
		},
		detectionUuidArray: function(detectionData){
			const detectionsArray = []
			detectionData.forEach((detection,index) => { //create array of detection uuids
				detectionsArray.push(detection.detectionUuid)
			});
			return detectionsArray
		},
		setMergeIndexByMergeUuid: function(targetMergeUuid) {
			let arrayOfMergeIndexes = this.globalMergedTracks;
			let oldArr = arrayOfMergeIndexes;

			let testFunction = function(arr) {
				let mergeUuid = arr.mergeUuid
				if (mergeUuid === targetMergeUuid) {
					return true
				}
			};

			let newArr = oldArr.map((val, index, arr) => {

				let mergeUuid = val.mergeUuid

				if (mergeUuid === targetMergeUuid) {
					return index
				} else {
					return null
				}
				// return element to new Array
			});
			let indexOfMergeUuid = newArr.filter(value => {if(value !== null){return value}})[0] //filter down to single value

			this.currentMergeTrack = indexOfMergeUuid;
			return indexOfMergeUuid
		},
		setTrackStyle: function(index) {
			let styleBuilder = "";
			const correctImages = this.correctImages;
			// styleBuilder += 'background: red;';
			if (correctImages.includes(index) === false){
				styleBuilder += `border-color: red;`;
				styleBuilder += `background: red;`;
			}

			return styleBuilder;
		},
		setTrackStyleByDetection: function(detection, localTrackUuid) {
			let styleBuilder = "";
			
			if(new Set(this.selectedDetections[localTrackUuid]).has(detection)){
				styleBuilder += "border: 2px solid red;";
			}
			styleBuilder+=this.imageCardSize.css;
			return styleBuilder;
			
		},
		detectionUuidtoLocalTrackUuidMapFunction(){
			const detectionUuidtoLocalTrackUuidMap = new Map();
			this.unassignedLocalTracks.forEach((localTrackObject)=>{
				detectionUuidtoLocalTrackUuidMap.set(localTrackObject.detectionUuid, localTrackObject.id)
			})
			return detectionUuidtoLocalTrackUuidMap
		},
		/**
		* UpdateAfterReassignment
		* Used to update the UI after reassigning a localtrack to a new merge.
		* NOTE: This would not be necessary if we made proper use of state management and reactivity.
		*/
		UpdateAfterReassignment({oldMergeUuid, newMergeUuid})
		{
			// Update selected track to have new mergeUuid
			this.activeLocalTrack.mergeUuid = newMergeUuid;

			// Update dataBuffer where mergeUuids exist, to ensure loading new pages doesn't mess up
			this.localTracks = this.localTracks.filter(localTrack => localTrack !== this.activeLocalTrack);
			delete this.dataBuffer[newMergeUuid];
			this.dataBuffer[oldMergeUuid].localTracks = this.localTracks;

			// Add to globalMergedTracks if new a UUID is created
			if (this.globalMergedTracks.indexOf(newMergeUuid) === -1)
			{
				this.globalMergedTracks.push(newMergeUuid);
			}
		},
		handleLocalTrackClick(localTrack){
			if(this.batchSelect){

				if (this.selectedLocalTracks.includes(localTrack.localTrackUuid)) {
					this.selectedLocalTracks = this.selectedLocalTracks.filter(r=>r !== localTrack.localTrackUuid);
				}
				else this.selectedLocalTracks.push(localTrack.localTrackUuid);
			}

		},
		async mergeFromBatchSelect(){
			try{
				// give backend parent mergeUuid to create data structure of consolidation errors to not repeat
				const body = {
					localTrackUuids: this.selectedLocalTracks,
					requiresConsolidation: 'requiresConsolidation_from_review',
					dataset: this.currentLocalTrackList,
					parentMergeUuid: this.currentMergeUuid
				}

				await dataAPI.post(`${this.db}/updateLocalTrackMergeUuid`, body);

				this.selectedLocalTracks.forEach(localTrackUuid=>{
					this.$delete(this.localTrackMap, localTrackUuid);
				});
				this.$noty.info(`Successfully moved ${this.selectedLocalTracks.length} to new mergeUuid;`);
				this.selectedLocalTracks = [];
			}
			catch(error){
				this.$noty.error(`Unable to move local tracks to new merge: ${error}`, {layout: 'bottomLeft'});
			}
		},
		async getMergesOfLength(){
			const body = {
				dataset: this.currentLocalTrackList,
				mergeLength: this.selectedMergeLength
			}
			try{
				const result = (await dataAPI.post(`${this.db}/datasets/getMergesByLength`,body)).data;

				if(result.length>0){
					this.mergeDataSource = 'acceptedMerges';
					this.globalMergedTracks = result.map(mergeTrack => mergeTrack.mergeUuid);
					this.pipeLineProgress['mergeUuids'] = true;
					this.getMergeUuidData(this.globalMergedTracks[0]);
				}
				else{
					this.$noty.info(`No merges of length ${this.selectedMergeLength}`);
				}
			}
			catch(error){
				this.$noty.error(`Error occured when retrieving merges of length ${this.selectedMergeLength}, ${error}`);
			}
		},
		async getStaffMerges(){
			try{
				
				const { data: result } = await dataAPI.post(`${this.db}/datasets/getStaffMerges`, {
					dataset: this.currentLocalTrackList
				});

				if(result.length > 0){
					this.mergeDataSource = 'acceptedMerges';
					this.globalMergedTracks = result.map(mergeTrack => mergeTrack.mergeUuid);
					this.pipeLineProgress['mergeUuids'] = true;
					this.getMergeUuidData(this.globalMergedTracks[0]);
				}
				else{
					this.$noty.error(`No staff merges to show`, {layout: 'bottomLeft'});
				}
			}
			catch(error){
				this.$noty.error(`Error occured when retrieving staff merges, ${error}`, {layout: 'bottomLeft'});
			}
		},
		/**
		 * Get 'trash' merges. All images that have been moved into the trash table
		 */
		async getDeletedMerges(){
			const type = "garbage";
			
			try{
				const { data: result } = await dataAPI.post(`${this.db}/garbage/merges`, {
					inputTable: this.currentLocalTrackList,
					type
				});

				if(result.length > 0){
					this.mergeDataSource="garbage";
					this.globalMergedTracks = result.map(mergeTrack => mergeTrack.mergeUuid);
					this.pipeLineProgress['mergeUuids'] = true;
					this.getMergeUuidData(this.globalMergedTracks[0]);
				}
				else{
					this.$noty.error(`No deleted merges to show`, {layout: 'bottomLeft'});
				}
			}
			catch(error){
				this.$noty.error(`Error occured when retrieving deleted merges, ${error}`, {layout: 'bottomLeft'});
			}
		},
		/*
		moveLocalTrackToTrash(localTrack):
		Removes a local track from merge and assigns it to trash. Updates UI accordingly
		*/
		async moveLocalTrackToTrash(localTrack){
			let confirm = window.confirm('Are you sure? Only remove items that are of non human images');
			let detectionInfo = this.localTrackInfoMap.get(localTrack.localTrackUuid);
			
			detectionInfo={
				...detectionInfo,
				localTrackUuid: localTrack.localTrackUuid
			}

			if(confirm){
				// moves local tracks to a garbage table - for junk detections
				try{
					const movedToTrash = (await dataAPI.post(`/${this.db}/garbage`, {mergeEntries: [detectionInfo], type: 'garbage', dataset: this.currentLocalTrackList, taskUuid: this.taskUuid}));
					const deletedFromMerge = (await dataAPI.post(`/${this.db}/review/removeLocalTrack/id/${localTrack.localTrackUuid}`, {fromComponent: 'reviewer', localTrackUuid: localTrack.localTrackUuid}));
					this.workingLocalTracks.push(localTrack); // add removed local track to working variable
					this.localTracks = this.localTracks.filter(obj => obj.localTrackUuid !== localTrack.localTrackUuid);
					// quality of life, make sure state of merge uuid change is reflected when switching merges and switching back
					this.getMergeUuidData(this.currentMergeUuid, true); 
					this.$noty.success('Moved local track to trash',{layout:'bottomLeft'});
				}
				catch(error){
					this.$noty.error('Unable to move local track to trash',{layout:'bottomLeft'});
				}
			}
		},
		toggleImageCardSize: function() {
			this.size = this.size === 'xs' ? 'xl' : 'xs';
		},
		async showFullFrame(){
			const { fullFramePath, sizeMap, subFrame } = await this.retrieveFullFrameDetails(this.currentDetectionUuid);
			this.currentFullFrameImage = fullFramePath;
			this.fullFramePathSizeMap = sizeMap;
			this.currentFullFrameSubFrame = {
				...subFrame
			}
			this.$root.$emit('bv::show::modal', 'fullframe-modal');
		},
		async retrieveFullFrameDetails(detectionUuid){
			const framePathMap = new Map();
			const frameWithSubframes = await this.retrieveFramePaths([ {detectionUuid} ], false, this.currentLocalTrackList, true);

			const [{ minimumY: top, maximumX: right, maximumY: bottom, minimumX: left }] = frameWithSubframes;
		
			frameWithSubframes.forEach(detection => framePathMap.set(detection.detectionUuid, detection.frame));

			const fullFramePath = framePathMap.get(detectionUuid);

			const sizeMap = await createImageSizeMap(framePathMap, true);
	
			return {
				fullFramePath, 
				sizeMap,
				subFrame: {
					top, right, bottom, left
				}
			}
		}
	}
}
</script>

<style scoped>
.local-track-list {
	display: flex;

	flex-wrap: wrap;
	width: 100%;
}


.frame-item.expanded:hover{
	border:2px solid #268BD2;

}
.frame-item[data-src]{
	border: 5px solid red;
}

.local-track-list .local-track {
	box-sizing: border-box;
	padding: 10px;
}
.track-highlight {
	display: inline-block;
	border-style: solid;
	border-top-width: 3px;
	border-bottom-width: 3px;
	margin-top: 2px;
	margin-bottom: 2px;
	background-color: lightgrey;
	border-color: gray;
}
.local-track-list .local-track.col2 {
	flex: 1 0 50%;
}
.local-track-list .local-track.col3 {
	flex: 1 0 33%;
}
.local-track-list .local-track.col4 {
	flex: 1 0 25%;
}
.local-track-list .local-track.col5 {
	/* flex: 1 0 10%; */
}
.local-track .frame-item {
	float: left;
}

.local-track-list pre {
	float: left;
	font-size: 12px;
}


.mergeUuidLinking {
	/* display: inline-block; */
	position: relative;
	margin: 3px;
	width: 660px;
	height: 120px;
	overflow:hidden;
}
.btn-outline-warning {
	padding-right: 4px;
	padding-left: 4px;
	margin-left: 6px;
	margin-right: 6px;
	width: 4em;
}

.sidebar {
	display: flex;
	flex-direction:column;
	margin-left:-40px;
	margin-top: 0;
	background-color: #1E434A;
	height: 95.4vh;
	position: fixed;
	display: block;
	width: 12%;
	padding-left:5px;
	padding-right:5px;
	border: 1px solid #839496;
	border-radius: 2px;
	z-index: 999;
}

.sidebar-scroll-wrapper {
	position: relative;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden auto;
	max-height: 100%;
}

.footer {
	width: 100%;
	margin-top: auto;
}

#mergeList:hover {
	background-color: #002B36;
	opacity: 0.5;
}
#mergeList #active {
	background-color: #268BD2;
	opacity: 1;
}

.btn-outline.expanded {
	background-color: red;
	opacity: 1;
	color: white;
}

</style>
